.table-row {
    .td-ranking {
        font-size: var(--font-size-xl);
        font-family: var(--font-family-heavy);
        padding-left: var(--spacing-s);
        color: var(--color-brand-primary-500);
    }
}


@media screen and (max-width: 768px) {
    .table-row {
        .td-profession {
            font-size: var(--font-size-s);
        }
    }
}