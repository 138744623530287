.platform-header {
    --_color-header-background: var(--color-neutral-light-0);
    --_color-header-text: var(--color-font-500);
    --_spacing-top-navigation-dropdown: 64px;

    background-color: transparent;
    color: var(--_color-header-text);
    padding: 12px 0;
    z-index: var(--z-index-100);

    &.sticky {
        background-color: var(--_color-header-background);
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);

        --_spacing-top-navigation-dropdown: 50px;
    }

    > .platform-header-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .breadcrumbs {
            flex-grow: 1;
            overflow: hidden;
            white-space: nowrap;
        }

        > .actions {
            display: flex;
            align-items: center;
            gap: 8px;

            > .navigation {
                position: relative;
                display: flex;
                gap: 16px;
                align-items: center;

                > .navigation-toggle {
                    cursor: pointer;
                }

                > .navigation-dropdown {
                    position: absolute;
                    top: var(--_spacing-top-navigation-dropdown);
                    right: 0;
                    border-radius: 8px;
                    min-width: 220px;
                    padding: 8px;
                    background-color: white;

                    > .divider {
                        width: 90%;
                        margin: 4px auto;
                        border-top: 1px solid rgba(0, 0, 0, 0.05);
                    }

                    .dropdown-item {
                        padding: 8px 12px;
                        border-radius: 8px;
                        display: grid;
                        grid-template-columns: 24px 1fr;
                        gap: 12px;
                        align-items: center;

                        i {
                            text-align: center;
                            color: rgba(0, 0, 0, 0.65);
                        }

                        .language-flag {
                            margin: 0 auto;
                            height: 16px;
                            border-radius: 4px;
                        }

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.05);
                        }

                        &--highlight {
                            color: var(--color-brand-primary-500);

                            i {
                                color: var(--color-brand-primary-500);
                            }
                        }

                        &--no-hover {
                            cursor: default;

                            &:hover {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }

            > .mobile-menu-toggle {
                padding: 4px var(--spacing-xs);
                color: var(--color-neutral-dark-400);
            }

            #login {
                padding: 6px 16px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        > .platform-header-inner {
            max-width: 90vw;
        }
    }
}
