@import "~styles/base/mixins.scss";

.development-overview {
    @include fullPageHeight;
    position: relative;
    
    .locked-overview {

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(211, 239, 255, 0.4);
            z-index: 3;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(211, 239, 255, 0.3) 0%, rgba(211, 239, 255, 1) 100%);
            z-index: 3;
        }

        .locked-info {
            position: absolute;
            top: 6%;
            z-index: 4;

            .cta-full-card {
                box-shadow: var(--shadow-overlay);
            }

            @media screen and (max-width: 768px) {
                top: 10%;
            }
        }
    }
    
    .introduction {
        background-color: var(--color-neutral-lightblue-100);
        .title {
            margin-bottom: var(--spacing-m);

            > .name {
                color: var(--color-brand-secondary-500);
            }
        }

        .description {
            margin-bottom: var(--spacing-m);
            max-width: 70ch;
            text-wrap: balance;
            white-space: pre-line;
        }
    }

    .filters {
        width: 100%;
        margin-left: auto;

        @media screen and (min-width: 768px) {
            width: 50%;
        }
    }

    .categories {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: calc(50% - var(--border-width-l));
            height: 100%;
            opacity: 0.4;
            border-left: 6px dashed var(--color-brand-secondary-500);
        }
    }

    .feedback {
        background-color: var(--color-neutral-lightblue-100);
    }
}
