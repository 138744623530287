/* Forms */

.form-label {
    padding-left: 0.5rem;

    &.no-edit {
        padding-left: 0;
    }
}

.form-textarea {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 100px;
    max-height: 100px;
    margin-bottom: 10px;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border: none;
    border-radius: var(--border-radius-xl);
    padding: 0;
    overflow: hidden;

    textarea {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        min-height: 100px;
        max-height: 100px;
        padding: 15px;
        resize: none;
        display: inline-block;
        outline: none;
        border: none;
        box-sizing: border-box;
        font-family: "Roboto", sans-serif;
        font-size: 15px;
    }
}

.form-control {
    height: auto;
    border: none;
    line-height: 1;
    border-radius: var(--border-radius-xl);
    padding: 0.875rem 1rem;
    outline: none !important;
    box-shadow: none !important;

    &.readonly {
        color: rgba(0, 0, 0, 0.5);
        cursor: not-allowed;
    }

    &:focus {
        outline: -webkit-focus-ring-color auto 1px !important;
    }
}

.form-toggle {
    display: flex;
    gap: 1rem;

    .form-toggle-option {
        background-color: var(--wit);
        color: var(--zwartblauw);
        padding: 0.5rem 2rem;
        border-radius: 5rem;
        border: 2px solid transparent;

        &.selected {
            border: 2px solid var(--zwartblauw);
        }
    }
}

.form-select {
    cursor: pointer;
    height: auto;
    border: none;
    line-height: 1;
    border-radius: 26px;
    padding: 1rem;
    box-shadow: none !important;

    &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px !important;
    }
}

.form-switch {
    scale: 1.25;

    > .form-check-input {
        cursor: pointer;

        &:focus {
            border-color: rgba(0, 0, 0, .25);
            box-shadow: none;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
        }

        &:focus-visible {
            outline: -webkit-focus-ring-color auto 1px !important;
        }

        &:checked {
            background-color: var(--color-purple-500);
            border-color: var(--color-purple-500);
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
        }
    }
}

.input-group {
    .input-group-text {
        border: none;
        background-color: var(--wit);
        color: rgba(0, 0, 0, 0.5);

        &.icon-left {
            padding-left: 16px;
            padding-right: 8px;
            border-radius: var(--border-radius-xl) 0 0 var(--border-radius-xl);
        }

        &.icon-right {
            border-radius: 0 var(--border-radius-xl) var(--border-radius-xl) 0;
        }
    }
}
