@import "../../../styles/base/mixins.scss";

.profession-slide {
    position: relative;
    width: 440px;
    height: 280px;
    overflow: hidden;
    border-radius: var(--border-radius-xl);
    @include slideHoverEffect;

    @media screen and (max-width: 768px) {
        width: 100%;
        height: 200px;
    }

    .bg-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: blur(0px);
    }

    .index {
        position: absolute;
        top: 4px;
        left: 20px;
        z-index: 1;
        color: var(--geel);
        font-size: 2.5rem;
        font-family: var(--font-family-black);
    }

    .favorite-toggle {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;
    }

    .content {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 20px;
        background: linear-gradient(to top, rgba(29, 25, 54, 1), rgba(29, 25, 54, 0));
        color: white;

        > .title {
            color: var(--color-font-0);
            font-family: var(--font-family-bold);
        }

        > .description {
            font-size: 15px;
            margin: 0.75rem 0;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            min-height: 63px;
        }

        @media screen and (max-width: 768px) {
            padding: 12px;
            
            > .title {
                font-size: 18px;
            }

            > .description {
                font-size: 14px;
            }
        }
    }
}
