.attributes-detail-modal {
    .description {
        > .title {
            color: var(--color-font-300);
            margin-bottom: var(--spacing-s);
        }
    }

    .origins {
        margin-top: var(--spacing-xl);

        > .title {
            color: var(--color-font-300);
            margin-bottom: var(--spacing-s);
        }

        > .origin {
            display: grid;
            grid-template-columns: 32px 1fr;
            gap: var(--spacing-s);
            margin-bottom: var(--spacing-xs);

            > .icon {
                color: var(--color-brand-secondary-200);
            }

            > .link {
                text-decoration: underline;

                &:hover {
                    color: var(--color-brand-secondary-600);
                }
            }
        }
    }

    .feedback {
        margin-top: var(--spacing-2xl);

        > .description {
            color: var(--color-font-300);
            margin-bottom: var(--spacing-s);
        }

        > .options {
            display: flex;
            gap: var(--spacing-s);

            > .option {
                display: flex;
                flex-direction: column;
                min-width: 80px;
                gap: var(--spacing-xs);
                align-items: center;
                padding: var(--spacing-xs) var(--spacing-s);
                border-radius: var(--border-radius-m);
                background-color: var(--color-neutral-light-0);
                border: var(--color-neutral-light-400) 2px solid;

                --_color-button-text-hover: black;

                &.disagree {
                    --_color-button-text-hover: var(--color-danger-600);
                }

                &.neutral {
                    --_color-button-text-hover: var(--color-brand-primary-500);
                }

                &.agree {
                    --_color-button-text-hover: var(--color-success-600);
                }

                &:hover, &.selected {
                    border-color: var(--_color-button-text-hover);

                    > .icon,
                    > .text {
                        color: var(--_color-button-text-hover);
                    }
                }

                > .icon {
                    font-size: 1.5rem;
                    color: var(--color-font-200);
                }

                > .text {
                    font-size: var(--font-size-s);
                    color: var(--color-font-300);
                }
            }
        }
    }
}
