.profile-evaluation {
    .feedback {
        > .title {
            padding-bottom: var(--spacing-m);
            color: var(--color-font-500);
        }

        > .description {
            padding-bottom: var(--spacing-m);
            max-width: 65ch;
        }
    }
}
