@import "~styles/base/mixins.scss";

.learningPath-detail {
    @include fullPageHeight;

    > .header {
        position: relative;

        .header-img {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 50%;
            pointer-events: none;

            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: "";
                display: block;
                background-image: linear-gradient(to bottom, var(--color-main-background), transparent 10%);
            }

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: "";
                display: block;
                background-image: linear-gradient(to right, var(--color-main-background), transparent 20%);
            }
        }

        .info {
            display: flex;
            gap: var(--spacing-m);
            align-items: center;

            > .type {
                padding: var(--spacing-xs) var(--spacing-m);
                width: fit-content;
                background-color: var(--color-neutral-light-0);
                font-size: var(--font-size-s);
                border-radius: var(--border-radius-round);

                > .icon {
                    color: var(--color-neutral-dark-200);
                }
            }
        }

        .title {
            font-size: 2rem;
            font-family: var(--font-family-heavy);
            margin: 1.5rem 0;
            display: flex;
            align-items: center;

            .favorite-toggle {
                margin-left: 1rem;
                font-size: 1rem;
            }
        }

        .actions {
            margin: 1.5rem 0;
        }
    }

    .content-title {
        font-size: 1.5rem;
        font-family: var(--font-family-heavy);
        color: var(--donkerblauw);
        margin-bottom: 1.5rem;
    }

    @media (max-width: 1300px) {
        > .header {
            .header-img {
                width: 100%;
                mask-image: none;
                opacity: 0.25;
                filter: blur(3px);
            }
        }
    }
}
