.breadcrumb {
    --bs-breadcrumb-margin-bottom: 0;
    --bs-breadcrumb-item-active-color: var(--color-font-500);
    --bs-breadcrumb-divider-color: var(--color-neutral-dark-400);
    --_sizing-breadcrumb-item-font-size: var(--font-size-m);

    .breadcrumb-item {
        display: flex;
        align-items: center;
        font-size: var(--_sizing-breadcrumb-item-font-size);
        transition: all 0.3s ease-in-out;

        .link {
            color: inherit;
            max-width: 20ch;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                text-decoration: underline;
            }
        }

        &.active {
            font-size: var(--font-size-xl);
            font-family: var(--font-family-bold);

            &::before {
                font-family: var(--font-family-regular);
                font-size: var(--_sizing-breadcrumb-item-font-size);
                color: var(--color-neutral-dark-400);
            }
        }

        &:last-child {
            margin-right: var(--spacing-m);
            flex: 1;
            overflow: hidden;

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    &.sticky {
        --_sizing-breadcrumb-item-font-size: var(--font-size-s);

        .breadcrumb-item {
            &.active {
                font-size: var(--font-size-m);
            }
        }
    }
}
