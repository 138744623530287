.create-profile-tile-content {
    position: relative;
    display: flex;
    flex-direction: column;

    > .subtitle {
        color: var(--color-yellow-500);
        margin-bottom: var(--spacing-xs);
    }

    > .title {
        color: var(--color-neutral-light-0);
        margin-bottom: var(--spacing-m);
    }

    > .description {
        color: var(--color-neutral-light-0);
        font-size: var(--font-size-s);
        margin-bottom: var(--spacing-xl);
    }

    > a {
        align-self: flex-end;
    }
}
