@import "~styles/base/mixins.scss";

.professions-guide {
    @include fullPageHeight;
    position: relative;

    .locked-overview {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(211, 239, 255, 0.4);
            z-index: 1;
        }
        
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(211, 239, 255, 0.3) 0%, rgba(211, 239, 255, 1) 100%);
            z-index: 1;
        }

        .locked-info {
            position: absolute;
            top: 20%;
            z-index: 2;

            .cta-full-card {
                box-shadow: var(--shadow-overlay);
            }

            @media screen and (max-width: 768px) {
                top: 10%;                
            }
        }
    }
}
