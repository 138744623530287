.useful-links {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: var(--border-radius-xl);
    padding: var(--spacing-xl);

    .title {
        margin-bottom: var(--spacing-l);
    }

    ul {
        list-style-type: none;
        padding: 0;

        li {
            margin-bottom: 0.5rem;

            a {
                color: var(--color-brand-secondary-500);
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
