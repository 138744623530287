.swiper-container {
    .swiper-wrapper {
        margin-bottom: 40px;
    }

    --swiper-navigation-size: 20px;
    --swiper-navigation-sides-offset: 24px;

    --swiper-theme-color: rgb(214, 214, 214);
    --swiper-pagination-color: rgb(168, 168, 168);
    --swiper-scrollbar-size: 14px;
    --swiper-scrollbar-bg-color: rgba(255, 255, 255, 0.3);
    --swiper-scrollbar-drag-bg-color: rgba(255, 255, 255, 1);

    .swiper-button-prev,
    .swiper-button-next {
        color: var(--zwartblauw);

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: var(--wit);
            z-index: 1;
        }

        &::after {
            z-index: 2;
            font-weight: 700;
        }

        &.swiper-button-disabled {
            opacity: 0;
        }
    }

    .swiper-button-prev {
        &::before {
            left: calc(50% + 2px);
        }
    }

    .swiper-button-next {
        &::before {
            left: calc(50% - 2px);
        }
    }

    @media screen and (max-width: 768px) {
        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }
}
