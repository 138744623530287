.button {
    --_color-button-text: var(--color-font-0);
    --_color-button-text-hover: var(--color-font-0);
    --_color-button-background: var(--color-brand-primary-500);
    --_color-button-background-hover: var(--color-brand-primary-600);
    --_color-button-border-hover: var(--color-brand-primary-600);
    --_color-button-border: var(--color-brand-primary-500);
    --_border-radius-button: var(--border-radius-round);
    --_border-width-button: var(--border-width-m);
    --_font-size-button: var(--font-size-m);
    --_font-weight-button: var(--font-weight-bold);
    --_border-style-button: solid;
    --_text-decoration-button: none;
    
    &--primary {
        --_color-button-text: var(--color-font-0);
        --_color-button-background: var(--color-brand-primary-500);
        --_color-button-background-hover: var(--color-brand-primary-600);
        --_color-button-border: var(--color-brand-primary-500);
        --_color-button-border-hover: var(--color-brand-primary-600);
    }

    &--secondary {
        --_color-button-text: var(--color-purple-500);
        --_color-button-text-hover: var(--color-font-0);
        --_color-button-background: transparent;
        --_color-button-background-hover: var(--color-purple-500);
        --_color-button-border: var(--color-purple-500);
        --_color-button-border-hover: var(--color-purple-500);
    }

    &--tertiary {
        --_color-button-text: var(--color-darkblue-500);
        --_color-button-text-hover: var(--color-font-900);
        --_color-button-background: transparent;
        --_color-button-background-hover: transparent;
        --_color-button-border: transparent;
        --_color-button-border-hover: transparent;
        --_text-decoration-button: underline;
    }

    &--white {
        --_color-button-text: var(--color-font-0);
        --_color-button-text-hover: var(--color-font-500);
        --_color-button-background: transparent;
        --_color-button-background-hover: var(--color-neutral-light-0);
        --_color-button-border: var(--color-neutral-light-0);
        --_color-button-border-hover: var(--color-neutral-light-0);
    }

    &--disabled, &--loading {
        opacity: 0.7;
        pointer-events: none;
    }

    cursor: pointer;
    display: inline-block;
    position: relative;
    color: var(--_color-button-text);
    background-color: var(--_color-button-background);
    border-radius: var(--_border-radius-button);
    border-width: var(--_border-width-button);
    border-color: var(--_color-button-border);
    font-size: var(--_font-size-button);
    font-weight: var(--_font-weight-button);    
    line-height: var(--line-height-xs);
    border-style: var(--_border-style-button);
    box-shadow: none;
    text-decoration: var(--_text-decoration-button);
    padding: var(--spacing-s) var(--spacing-m);
    min-width: fit-content;
    width: fit-content;

    &:hover {
        background-color: var(--_color-button-background-hover);
        border-color: var(--_color-button-border-hover);
        color: var(--_color-button-text-hover);
    }

    > .icon, > .loading-icon {
        margin-left: var(--spacing-s);
    }
}
