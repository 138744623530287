.feedback-status-overview {

    > .title {
        padding-top: var(--spacing-2xl);
        padding-bottom: var(--spacing-m);
    }

    .validator-list {
        background-color: var(--color-neutral-light-0);
        padding: var(--spacing-m);
        border-radius: var(--border-radius-m);
        
        > .validator {
            display: grid;
            grid-template-columns: 1fr 1fr auto;
            gap: 1rem;
            align-items: center;

            + .validator {
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                margin-top: 12px;
                padding-top: 12px;
            }

            > .status {
                font-family: var(--font-family-medium);

                &--completed {
                    color: var(--color-success-500);
                }
            }

            > .delete-btn {
                border: none;
                background-color: transparent;

                &:hover {
                    text-decoration: underline;
                }
            }

            > .show-link {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}