@import "~styles/base/mixins.scss";

.attribute-evaluation-card {
    position: relative;
    background-color: var(--color-neutral-light-0);
    padding: var(--spacing-xl);
    border-radius: var(--border-radius-xl);
    box-shadow: var(--shadow-raised);

    > .stepper {
        display: flex;
        justify-content: center;
    }

    > .attribute-list {
        border-top: 1px solid var(--color-neutral-light-300);
        margin-top: var(--spacing-xl);
        padding-top: var(--spacing-m);

        > .list-header {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: auto;
            align-items: center;
            padding-bottom: var(--spacing-s);
            padding-top: var(--spacing-m);
            border-radius: var(--border-radius-m);

            span:not(:first-child) {
                text-align: center;
                font-size: var(--font-size-m);
            }

            @media (max-width: 768px) {
                grid-template-columns: repeat(3, 1fr);
                text-align: center;

                :first-child {
                    display: none;
                }
            }
        }

        > .list-item {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: auto;
            align-items: center;
            padding: var(--spacing-2xs) var(--spacing-xs);
            border-radius: var(--border-radius-m);

            &:nth-child(even) {
                background-color: rgba(0, 0, 0, 0.05);
            }

            .attribute-name {
                font-family: var(--font-family-medium);
            }

            .feedback-option {
                width: 32px;
                height: 32px;
                margin: auto;
                background-color: transparent;
                border: var(--border-width-m) solid var(--color-neutral-light-500);
                border-radius: var(--border-radius-round);

                &--selected {
                    background-color: var(--color-purple-500);
                    border: 2px solid var(--color-purple-500);
                }
            }

            @media (max-width: 768px) {
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2, 1fr);

                .attribute-name {
                    grid-column: 1 / 5;
                    grid-row: 1 / 2;
                    padding: var(--spacing-xs) 0;
                    text-align: center;
                }

                .feedback-option {
                    grid-column: auto;
                    grid-row: 2 / 3;
                    margin-bottom: var(--spacing-xs);
                }
            }
        }
    }

    > .actions {
        display: flex;
        justify-content: space-around;
        margin-top: var(--spacing-xl);
    }

    > .no-attributes {
        text-align: center;
        padding: var(--spacing-xl) 0;
        font-family: var(--font-family-medium);
    }

    > .complete-action {
        display: flex;
        justify-content: center;
        margin-top: var(--spacing-l);
    }

    @media (max-width: 768px) {
        padding: var(--spacing-m);        
    }
}
