@import "~styles/base/mixins.scss";

.image-selection-measurement {
    @include fullPageHeight;

    .image-selection-measurement__title {
        text-align: center;
        font-family: var(--font-family-heavy);
        color: var(--donkerblauw);
        margin-bottom: 1rem;
        font-size: 1.25rem;
    }

    .image-selection-measurement__subtitle {
        text-align: center;
        font-family: var(--font-family-heavy);
        color: var(--oranje);
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        font-size: 2rem;
    }

    .image-selection-measurement__current-question {
        text-align: center;
    }

    .image-selection-measurement__question {
        .image-selection-measurement__image-selection {
            .image-selection-measurement__image {
                border: 4px solid var(--wit);
                width: 100%;
                max-width: 400px;
                border-radius: var(--border-radius-xl);
            }

            .image-selection-measurement__input {
                opacity: 0;

                &:hover + img,
                &:focus + img,
                &:checked + img {
                    border: 4px solid var(--oranje);
                }
            }

            .image-selection-measurement__image-title {
                text-align: center;
                margin-top: 8px;
                color: var(--oranje);
                font-family: var(--font-family-heavy);
            }

            &:hover {
                cursor: pointer;

                img {
                    border: 4px solid var(--oranje);
                }
            }
        }
    }
}
