.locked-card {
    position: relative;
    height: 360px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: var(--border-radius-xl);

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 50%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.1);
        border-radius: var(--border-radius-xl) var(--border-radius-xl) 0 0;
    }

    i {
        position: absolute;
        bottom: 60%;
        left: 50%;
        text-align: center;
        margin-left: -10px;
        color:rgba(0, 0, 0, 0.4)
    }
}

.locked-cta-card {
    position: absolute;
    left: 25%;
    top: 25%;
    height: fit-content;
    width: 50%;
    text-align: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    background-color: var(--color-neutral-light-0);
    border-radius: var(--border-radius-xl);

    @media screen and (max-width: 992px) {
        left: 5%;
        top: 10%;
        width: 90%;
    }
}
