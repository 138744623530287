@import "~bootstrap/scss/bootstrap-utilities";

/* Global styles & alias tokens */

:root {
    --color-main-background: var(--color-neutral-lightblue-200);
    --color-main-background-behind: var(--color-neutral-lightblue-300);
    --color-main-background-raised: var(--color-neutral-lightblue-100);
    --color-main-text: var(--color-font-500);
    --color-scrollbar-track-background: var(--color-neutral-light-200);
    --color-scrollbar-thumb-background: var(--color-neutral-light-400);
    --color-scrollbar-thumb-background-hover: var(--color-neutral-light-500);
    --font-family-main: var(--font-family-regular);
}

body {
    background-color: var(--color-main-background);
    color: var(--color-main-text);
    font-family: var(--font-family-main);

    p:last-of-type {
        margin-bottom: 0;
    }

    a {
        color: var(--color-main-text);
    }

    // Quick fix for description class styled in @keyvaluesystems/react-stepper package
    .description {
        color: unset;
        padding-bottom: unset;
    }

    // Quick fix for label class styled in @keyvaluesystems/react-stepper package
    .label {
        font-size: unset;
        font-weight: unset;
        white-space: unset;
        word-wrap: unset;
        text-align: unset;
        padding: unset;
        display: unset;
        max-width: unset;
    }
}

h1, h2, h3, h4, h5, h6 {
    color: var(--color-main-text);
    font-family: var(--font-family-heavy);
    line-height: var(--line-height-m);
    margin: 0;
}

h1 {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-heavy);
}

h2 {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-heavy);
}

h3 {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-heavy);
}

h4 {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-heavy);
}

h5 {
    font-size: var(--font-size-m);
    font-family: var(--font-family-bold);
    font-weight: var(--font-weight-bold);
}

h6 {
    font-size: var(--font-size-m);
    font-family: var(--font-family-medium);
    font-weight: var(--font-weight-medium);
}

body.noscroll,
html.noscroll {
    overflow-y: hidden;
    touch-action: none;
    -ms-touch-action: none;
}

/* width */
::-webkit-scrollbar {
    width: var(--size-xs);
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--color-scrollbar-track-background);
    border-radius: var(--border-radius-round);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--color-scrollbar-thumb-background);
    border-radius: var(--border-radius-round);

    &:hover {
        background: var(--color-scrollbar-thumb-background-hover);
    }
}
