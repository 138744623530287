@import "~styles/base/mixins.scss";

.profile {
    @include fullPageHeight;

    .profile-header {
        padding-top: 100px;

        .content {
            display: grid;
            grid-template-columns: 200px 1fr;
            gap: var(--spacing-m);

            > .picture {
                position: relative;
                top: -75px;
                width: 200px;
                height: 200px;
            }

            > .title {
                padding: 1rem;

                h1 {
                    font-size: 2rem;
                    font-family: var(--font-family-black);
                }

                p {
                    padding-top: 1rem;
                }
            }

            @media screen and (max-width: 768px) {
                grid-template-columns: 1fr;
                grid-template-rows: 200px 1fr;

                .picture {
                    margin: 0 auto;
                    top: -50px;
                }

                .title {
                    padding: 1rem;
                    text-align: center;

                    h1 {
                        font-size: 1.5rem;
                    }

                    p {
                        padding-bottom: 1rem;
                    }
                }
            }
        }
    }
}
