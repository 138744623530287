.goals-tile-content {
    position: relative;
    container: requirement / inline-size;

    > .title {
        margin-bottom: 1.5rem;
    }

    > .goals {
        display: flex;
        gap: 2rem;

        > .goal {
            > .title {
                margin-bottom: 1.5rem;
            }

            > .description {
                margin-bottom: 1rem;
            }
        }
    }

    @container requirement (max-width: 620px) {
        .goals {
            flex-direction: column;
            gap: 1.5rem;
        }
    }
}
