@import "~styles/base/mixins.scss";

.profession-detail {
    @include fullPageHeight;
    background-color: var(--color-lightblue-200);

    > .header {
        position: relative;
        background-color: var(--color-neutral-lightblue-400);

        .header-image {
            position: absolute;
            top: 0;
            height: 100%;
            left: 40%;
            right: 5%;
            padding-left: 15px;
            padding-right: 15px;
            pointer-events: none;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                clip-path: polygon(0 0, 80% 0, 100% 100%, 20% 100%);
            }

            @media (max-width: 1360px) {
                right: 0;
                left: 60%;

                img {
                    clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%);
                }
            }

            @media (max-width: 992px) {
                display: none;
            }
        }

        .title {
            font-size: 3rem;
            font-family: var(--font-family-heavy);
            margin: 1.5rem 0;
            display: flex;
            align-items: center;
            overflow-wrap: anywhere;

            .favorite-toggle {
                margin-left: 1rem;
                font-size: 1rem;
            }

            @media screen and (max-width: 992px) {
                font-size: 2rem;
            }
        }
    }

    .accordion {
        border: none;
        margin-bottom: 1.5rem;

        .accordion-item {
            border-radius: var(--border-radius-xl);
        }

        .accordion-header {
            .accordion-button {
                position: relative;
                border-radius: var(--border-radius-xl);
                background-color: var(--color-neutral-light-0);
                box-shadow: none;
                color: var(--color-brand-secondary-500);
                font-family: var(--font-family-heavy);
                font-size: 1.125rem;

                &:focus-visible {
                    outline: -webkit-focus-ring-color auto 1px !important;
                }
            }
        }
    }

    .learning-paths {
        > .title {
            font-size: 2rem;
            text-align: center;
            font-family: var(--font-family-heavy);
            margin-bottom: 2rem;
        }

        > .paths {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-l);
            border-radius: var(--border-radius-xl);
            background-color: rgba(255, 255, 255, 0.4);
        }
    }
}
