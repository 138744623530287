@import "~styles/base/mixins.scss";

.loading-info {
    @include fullPageHeight;
    background-color: var(--color-neutral-lightblue-200);
    display: flex;
    justify-content: center;

    > .content {
        margin-top: 20vh;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
