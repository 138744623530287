.profile-picture {
    position: relative;
    display: inline-block;

    .profile-picture__wrapper {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        z-index: 1;
    }

    .profile-picture__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        filter: blur(8px);
        z-index: 0;
    }

    .profile-picture__image, .profile-picture__placeholder {
        position: relative;
        display: inline-block;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            vertical-align: baseline;
        }
    }

    .profile-picture__loading {
        display: flex;
        align-items: flex-start;
    }

    .profile-picture__upload-button {
        display: flex;
        justify-content: center;
        align-items: center;

        label {
            cursor: pointer;
            text-decoration: underline;
            margin-top: 8px;
        }

        input {
            display: none;
        }
    }

    &.profile-picture--editable {
        .profile-picture__image,
        .profile-picture__placeholder {
            border: 4px solid var(--wit);
        }
    }
}
