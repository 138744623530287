@import "~styles/base/mixins.scss";

.essay-measurement {
    @include fullPageHeight;

    display: flex;
    flex-direction: column;
    align-items: center;

    textarea {
        border-radius: 25px 25px 0 0;
    }

    .essay-measurement__title {
        font-family: var(--font-family-heavy);
        color: var(--donkerblauw);
        margin-bottom: 1rem;
        font-size: 2rem;
    }

    .essay-measurement__requirements {
        border-radius: 0 0 25px 25px;
        border-top: 1px solid var(--color-neutral-light-300);
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--wit);
    }
}
