.onboarding-pitch {
    container: pitch / inline-size;

    > .loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: var(--spacing-m);
        min-height: 400px;

        > .title {
            color: var(--color-purple-500);
        }

        > .loading-bar-container {
            position: relative;
            overflow: hidden;
            width: 80%;
            height: var(--size-s);
            background-color: var(--color-neutral-light-300);
            border-radius: var(--border-radius-l);

            > .loading-bar {
                position: absolute;
                height: 100%;
                width: 50%;
                left: -50%;
                border-radius: var(--border-radius-l);
                background-color: var(--color-purple-500);
                animation: loading 2s ease-in 0.5s infinite;
            }
        }

        @keyframes loading {
            0% {
                transform: translateX(0);
            }
            to {
                transform: translateX(400%);
            }
        }
    }

    > .error {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: var(--spacing-m);
        min-height: 400px;

        > .title {
            color: var(--color-danger-500);
        }

        > .description {
            max-width: 60ch;
            text-align: center;
        }
    }

    > .pitch-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: var(--spacing-l);
    }

    @container pitch (min-width: 700px) {
        > .pitch-wrapper {
            display: grid;
            grid-template-columns: 1fr 200px;
            gap: var(--spacing-l);
        }
    }

    > .pitch-processed {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: var(--spacing-m);
        min-height: 400px;

        > .title {
            background: linear-gradient(to right, var(--color-purple-500) 0%, var(--color-orange-500) 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        > .description {
            max-width: 60ch;
            text-align: center;
        }
    }
}
