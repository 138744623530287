.profile-step {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);

    form {
        background: linear-gradient(
                        to right,
                        var(--color-lightblue-500) 0%,
                        var(--color-pink-500) 100%
        );
        border-radius: var(--border-radius-l);
        padding: 1rem 1rem 2rem 1rem;
    }
}
