@import "../../styles/base/mixins.scss";

.measurement-card {
    display: grid;
    grid-template-rows: auto 1fr;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--border-radius-xl);
    background-color: var(--color-neutral-light-0);
    @include cardHoverEffect;

    > .img {
        width: 100%;
        aspect-ratio: 16/9;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    > .favorite {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 48px;
        height: 48px;
        border-radius: 0 0 0 var(--border-radius-xl);
        background-color: rgba(0, 0, 0, 0.4);

        > div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    > .content {
        padding: 16px;
        height: 100%;
        display: grid;
        grid-template-rows: 1fr auto auto;
        position: relative;

        > .title {
            color: var(--paars);
            font-family: var(--font-family-medium);
            font-size: 1.25rem;
        }

        > .meta {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            margin: 0.75rem 0;

            .meta-item {
                color: var(--zwartblauw);
                font-size: 12px;
                padding: 4px 10px;
                border-radius: var(--border-radius-xl);
                border: 1px solid rgba(0, 0, 0, 0.15);
            }
        }

        > .description {
            font-size: 1rem;
            margin-bottom: 1rem;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        > .results-link {
            position: absolute;
            z-index: 1;
            top: -52px;
            left: var(--spacing-s);
            padding: var(--spacing-xs) var(--spacing-m);
            background-color: var(--color-neutral-light-0);
            border-radius: var(--border-radius-xl);

            &:hover {
                background-color: var(--color-neutral-light-100);
            }
        }
    }

    &.locked {
        pointer-events: none;

        > .locked-overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: rgba(255, 255, 255, 0.45);

            .icon {
                padding-top: 110px;
                font-size: var(--font-size-2xl);
                margin-bottom: var(--spacing-m);
                color: var(--color-brand-secondary-400);                
            }
        }
    }
}
