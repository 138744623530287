/* Buttons */

.btn {
    font-size: 0.7rem;
    font-weight: 900;
    line-height: 1;
    border-width: 2px;
    position: relative;
    border-style: solid;
    border-radius: 50px;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    box-shadow: none !important;
    text-decoration: none !important;
    padding: 0.8rem 1rem;
    width: fit-content;

    &.btn--zwartblauw {
        color: var(--wit);
        border-color: var(--zwartblauw);
        background: var(--zwartblauw);

        &:hover,
        &:focus,
        &.btn--active {
            color: var(--zwartblauw);
            border-color: var(--wit);
            background: var(--wit);
        }

        &.btn--transparent {
            color: var(--zwartblauw);
            background: transparent;

            &:hover,
            &:focus,
            &.btn--active {
                color: var(--wit);
                border-color: var(--zwartblauw);
                background: var(--zwartblauw);
            }
        }
    }

    &.btn--wit {
        color: var(--zwartblauw);
        border-color: var(--wit);
        background: var(--wit);

        &:hover,
        &:focus,
        &.btn--active {
            color: var(--wit);
            border-color: var(--zwartblauw);
            background: var(--zwartblauw);
        }

        &.btn--transparent {
            color: var(--wit);
            background: transparent;

            &:hover,
            &:focus,
            &.btn--active {
                color: var(--wit);
                border-color: var(--zwartblauw);
                background: var(--zwartblauw);
            }
        }
    }

    &.btn--oranje {
        color: var(--wit);
        border-color: var(--oranje);
        background: var(--oranje);

        &:hover,
        &:focus,
        &.btn--active {
            color: var(--wit);
            border-color: var(--zwartblauw);
            background: var(--zwartblauw);
        }

        &.btn--transparent {
            color: var(--oranje);
            background: transparent;

            &:hover,
            &:focus,
            &.btn--active {
                color: var(--wit);
                border-color: var(--oranje);
                background: var(--oranje);
            }
        }
    }

    &.btn--danger {
        color: var(--wit);
        border-color: var(--color-danger-500);
        background: var(--color-danger-500);

        &:hover,
        &:focus,
        &.btn--active {
            color: var(--wit);
            border-color: var(--zwartblauw);
            background: var(--zwartblauw);
        }

        &.btn--transparent {
            color: var(--color-danger-500);
            background: transparent;

            &:hover,
            &:focus,
            &.btn--active {
                color: var(--wit);
                border-color: var(--color-danger-500);
                background: var(--color-danger-500);
            }
        }
    }

    &:not(:last-of-type) {
        margin-right: 1rem;
    }
}

.btn-group,
.btn-group-vertical {
    .btn {
        &:not(:last-of-type) {
            margin-right: 0rem;
        }
    }
}

.btn-group-vertical {
    .btn {
        &:first-of-type {
            border-top-left-radius: 1.5rem;
            border-top-right-radius: 1.5rem;
        }

        &:last-of-type {
            border-bottom-left-radius: 1.5rem;
            border-bottom-right-radius: 1.5rem;
        }
    }
}
