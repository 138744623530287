.youtube-video-component {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;

    .youtube-video {
        width: 100%;
        height: 100%;
    }

    .youtube-video-ifame {
        width: 100%;
        height: 100%;
    }
}