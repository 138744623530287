.video-recorder {
    container: video-recorder / inline-size;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-m);
    padding-bottom: var(--spacing-m);
    border-radius: var(--border-radius-l);
    background-color: var(--color-neutral-light-200);

    > .video-container {
        position: relative;
        height: auto;

        .video {
            height: 100%;
            width: 100%;
            border-radius: var(--border-radius-l);
        }

        .download-popup {
            display: flex;
            align-items: flex-start;
            position: absolute;
            right: var(--spacing-s);
            bottom: 80px;
            padding: var(--spacing-s);
            border-radius: var(--border-radius-l);
            background: var(--color-purple-500);
            color: var(--color-neutral-light-0);
            border: var(--border-width-m) solid var(--color-neutral-light-0);

            span {
                max-width: 25ch;
            }

            button {
                width: 32px;
                background-color: transparent;
                border: none;
                color: var(--color-neutral-light-0);
            }
        }
    }

    > .warning {
        font-family: var(--font-family-bold);
        color: var(--color-danger-500);
        max-width: 50ch;
        text-align: center;
    }

    > .controls {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-s);
        align-items: center;
    }

    @container video-recorder (min-width: 540px) {
        > .controls {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }
}