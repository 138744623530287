.language-selector {
    .toggle {
        display: flex;
        align-items: center;
        background: transparent;
        font-size: 1rem;
        padding: 4px 8px 4px 0;
        border: none;
        font-weight: normal;
        color: var(--color-main-text);
        text-transform: uppercase;
        letter-spacing: normal;

        &:active {
            background: transparent;
            color: var(--color-main-text);
        }
    
        &:focus-visible {
            outline: -webkit-focus-ring-color auto 1px !important;
        }
    }
}