:root {
    --color-modal-background: var(--color-neutral-light-100);
    --color-modal-text: var(--color-font-500);
}

.wihv-modal {
    .modal-content {
        border: 0;
        border-radius: var(--border-radius-xl);
        background-color: var(--wit);

        .modal-header {
            padding: 1.5rem;
            border: 0;
            background: var(--color-neutral-light-200);
            border-radius: var(--border-radius-xl) var(--border-radius-xl) 0 0;

            .modal-title {
                font-size: 1.5rem;
                font-family: var(--font-family-heavy);
                color: var(--donkerblauw);
            }

            .btn-close {
                opacity: 1;
            }
        }

        .modal-body {
            padding: 1.5rem;
            background: var(--wit);
            max-height: 70vh;
            overflow-y: auto;

            label {
                margin-bottom: 0;
            }
        }

        .modal-footer {
            background: var(--color-neutral-light-200);
            border-radius: 0 0 25px 25px;
        }
    }

    &.wihv-modal--profile,
    &.feedback-providers-modal {
        .modal-content {
            border: 0;
            border-radius: 25px;
        }

        .modal-header {
            border: 0;
            padding: 1.5rem;
            background-color: var(--paars);
            border-radius: 25px 25px 0 0;

            .modal-title {
                font-size: 1.5rem;
                font-family: var(--font-family-heavy);
                color: var(--wit);
            }
        }

        .modal-body {
            background: rgba(125, 119, 255, 0.1);

            input,
            textarea,
            select,
            .search-control {
                border: 1px solid rgba(0, 0, 0, 0.2);
            }

            .wihv-modal__row {
                position: relative;
                border-radius: var(--border-radius-xl);
                border: 1px solid rgba(0, 0, 0, 0.1);
                background-color: rgba(0, 0, 0, 0.06);

                & + .wihv-modal__row {
                    margin-top: 1.5rem;
                }

                .wihv-modal__row-delete {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: -8px;
                    right: -5px;
                    width: 32px;
                    height: 32px;
                    border: none;
                    padding: 0.5rem;
                    background: var(--color-danger-500);
                    border-radius: 50%;
                    color: var(--wit);

                    &:hover,
                    &:focus {
                        background: darken(#ff5757, 10%);
                    }
                }
            }
        }

        .modal-footer {
            background-color: var(--wit);
            border-radius: 0 0 25px 25px;
        }
    }

    &.wihv-modal--info {
        .modal-header {
            .btn-close {
                color: var(--zwartblauw);
                opacity: 0.8;
            }
        }

        .modal-body {
            border-radius: 0 0 25px 25px;

            .info-modal__title {
                font-size: 1rem;
                font-family: var(--font-family-bold);
            }
        }
    }
}

.languages-modal {
    .languages-modal__toggle {
        width: fit-content;
    }

    .languages-modal__explainer {
        margin-top: 1rem;
        font-size: 13px;
        background-color: var(--wit);
        padding: 0.875rem;
        border-radius: var(--border-radius-xl);
    }
}
