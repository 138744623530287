.cookie-consent-disabled-placeholder {
    container-type: inline-size;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    background-color: var(--color-neutral-dark-900);
    border-radius: var(--border-radius-l);

    > .info-card {
        background-color: var(--color-neutral-light-200);
        border-radius: var(--border-radius-m);
        width: 60%;
        padding: var(--spacing-m) var(--spacing-l);
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        > .image {
            position: absolute;
            top: -20px;
            right: -20px;
            width: 48px;
            height: 48px;
        }

        > .title {
            margin-bottom: var(--spacing-m);
        }

        > .description {
            font-size: var(--font-size-s);
            margin-bottom: var(--spacing-m);
        }
    }

    @container (max-width: 768px) {
        > .info-card {
            width: 85%;
        }
    }

    @container (max-width: 480px) {
        > .info-card {
            width: 100%;
        }
    }
}
