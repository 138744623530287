@mixin cardHoverEffect {
    @media screen and (min-width: 768px) {
        transition:
            transform 0.3s ease-in-out,
            box-shadow 0.3s ease-in-out;

        &:hover {
            transform: scale(1.05);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        }
    }
}

@mixin slideHoverEffect {
    &:hover {
        .content {
            background: linear-gradient(to top, rgba(29, 25, 54, 1) 25%, rgba(29, 25, 54, 0));
        }
    }
}

@mixin fullPageHeight {
    min-height: calc(100vh - var(--sizing-header-height));
    min-height: calc(100dvh - var(--sizing-header-height));
}
