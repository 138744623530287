@import "~styles/base/mixins.scss";

.learning-resource-card {
    display: grid;
    grid-template-rows: auto 1fr;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--border-radius-xl);
    background-color: var(--wit);
    @include cardHoverEffect;

    --_type-color: var(--transparent);

    &--course {
        --_type-color: var(--roze);
    }

    &--path {
        --_type-color: var(--geel);
    }

    > .img {
        width: 100%;
        aspect-ratio: 16/9;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    .learning-resource__provider-logo {
        position: absolute;
        z-index: 2;
        bottom: 0; 
        left: 0;
        padding: 10px 12px;
        border-radius: 0 var(--border-radius-xl) 0 0;
        background-color: rgba(0, 0, 0, 0.4);
        display: inline-flex;
        align-items: center;
        gap: 8px;
    }

    .learning-resource__provider-logo i.icon {
        font-size: 1.5rem;
        color: white;
    }

    .learning-resource__provider-logo .supplier-text {
        font-size: 1rem;
        color: white;
    }

    > .banners {
        display: flex;
        gap: 8px;
        z-index: 1;
        position: absolute;
        top: -1px;
        left: 24px;
        width: fit-content;

        > .type {
            width: 48px;
            height: 48px;
            background-color: var(--_type-color);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0 0 4px 4px;
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 70%, 0 100%);
    
            i {
                position: relative;
                top: -6px;
            }
        }

        > .completed {
            width: 48px;
            height: 48px;
            background-color: var(--color-neutral-light-100);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0 0 4px 4px;
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 70%, 0 100%);
    
            i {
                position: relative;
                top: -6px;
                color: var(--color-success-500);
            }
        }
    }

    > .favorite {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 48px;
        height: 48px;
        border-radius: 0 0 0 var(--border-radius-xl);
        background-color: rgba(0, 0, 0, 0.4);

        > div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    > .content {
        padding: 16px;
        height: 100%;
        display: grid;
        grid-template-rows: 1fr auto auto;

        > .title {
            color: var(--paars);
            font-family: var(--font-family-medium);
            font-size: 1.25rem;
        }

        > .meta {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            margin: 0.75rem 0;

            .meta-item {
                color: var(--zwartblauw);
                font-size: 12px;
                padding: 4px 10px;
                border-radius: var(--border-radius-xl);
                border: 1px solid rgba(0, 0, 0, 0.15);
            }
        }

        > .description {
            font-size: 15px;
            margin-bottom: 1rem;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}
