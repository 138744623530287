.recommended-professions-podium {
    display: grid;
    grid-template-areas: "second first third";
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: var(--spacing-m);
    height: 100%;
    padding-top: var(--spacing-l);

    > .podium-item {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: var(--spacing-m);
        background-color: var(--color-neutral-light-0);
        border-radius: var(--border-radius-l);
        box-shadow: var(--shadow-l);

        > .ranking {
            position: absolute;
            top: -44px;
            font-size: var(--font-size-3xl);
            font-family: var(--font-family-black);
            color: var(--color-orange-500);
        }

        > .percentage {
            position: absolute;
            top: 8px;
            right: 16px;
            font-size: 24px;
            font-family: var(--font-family-black);
            color: var(--color-orange-500);
        }

        > .content {
            flex-grow: 1;

            > .title {
                margin-top: var(--spacing-l);
                color: var(--color-darkblue-500);
                word-wrap: break-word;
            }

            > .description {
                margin: var(--spacing-m) 0;
            }
        }

        &--first {
            grid-area: first;
        }

        &--second {
            grid-area: second;
            margin-top: 48px;
        }

        &--third {
            grid-area: third;
            margin-top: 96px;
        }
    }

    @media (max-width: 1000px) {
        grid-template-areas: "first" "second" "third";
        grid-template-columns: 1fr;
        gap: var(--spacing-2xl);

        .podium-item {
            &--second,
            &--third {
                margin-top: 0;
            }
        }
    }
}
