.divider {
    border-bottom: 1px solid rgba(12, 23, 50, 0.1);
}

.w-fit-content {
    width: fit-content;
}

.default-br {
    border-radius: var(--border-radius-xl);
}

.bg-transparent {
    background: transparent;
}