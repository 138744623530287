.dashboard-tile {

    --_color-dashboard-tile-background: var(--color-neutral-light-0);
    --_color-dashboard-tile-text: var(--color-font-500);
    --_border-radius-dashboard-tile: var(--border-radius-l);
    --_spacing-dashboard-tile: var(--spacing-l);
    --_shadow-dashboard-tile: var(--shadow-raised);

    position: relative;
    background-color: var(--_color-dashboard-tile-background);
    border-radius: var(--_border-radius-dashboard-tile);
    padding: var(--_spacing-dashboard-tile);
    box-shadow: var(--_shadow-dashboard-tile);

    > .background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: var(--_border-radius-dashboard-tile);
        overflow: hidden;
    
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.4);
        }
    }

    > .content {
        height: 100%;
    }
}