:root {
    --wit: #ffffff;
    --groen: #1ca796;
    --donkerblauw: #041db7;
    --paars: #7d77ff;
    --blauw: #a5e2f4;
    --lichtblauw: #c9edf8;
    --oranje: #fe7225;
    --zwartblauw: #0c1732;
    --lila: #e0d9fb;
    --roze: #fae2fc;
    --lichtgeel: #fcffce;
    --geel: #f5ff77;
    --donkergeel: #dcaf22;

    // Value tokens

    // Colors
    --color-success-100: #a0dcc1;
    --color-success-200: #7acda8;
    --color-success-300: #67c69c;
    --color-success-400: #54bf8f;
    --color-success-500: #41b883;
    --color-success-600: #3ba676;
    --color-success-700: #349369;
    --color-success-800: #2e815c;
    --color-success-900: #276e4f;

    --color-danger-100: #fab2b2;
    --color-danger-200: #f89393;
    --color-danger-300: #f78484;
    --color-danger-400: #f67474;
    --color-danger-500: #f56565;
    --color-danger-600: #dd5b5b;
    --color-danger-700: #c45151;
    --color-danger-800: #ac4747;
    --color-danger-900: #933d3d;
    
    --color-favorite-100: #ffec8b;
    --color-favorite-200: #ffe978;
    --color-favorite-300: #ffe565;
    --color-favorite-400: #ffe251;
    --color-favorite-500: #ffdf3e;
    --color-favorite-600: #f3d44a;
    --color-favorite-700: #d9c542;
    --color-favorite-800: #c0b63b;

    --color-neutral-light-0: #ffffff;
    --color-neutral-light-100: #fafafa;
    --color-neutral-light-200: #f5f5f5;
    --color-neutral-light-300: #e5e5e5;
    --color-neutral-light-400: #d4d4d4;
    --color-neutral-light-500: #bcbcbc;
    --color-neutral-light-600: #a3a3a3;
    --color-neutral-light-700: #737373;

    --color-neutral-dark-200: #797775;
    --color-neutral-dark-300: #484644;
    --color-neutral-dark-400: #3b3a39;
    --color-neutral-dark-500: #323130;
    --color-neutral-dark-600: #292827;
    --color-neutral-dark-700: #252423;
    --color-neutral-dark-800: #201f1e;
    --color-neutral-dark-900: #1b1a19;
    --color-neutral-dark-1000: #000000;

    --color-font-0: #ffffff;
    --color-font-50: #e7e8eb;
    --color-font-100: #ced1d6;
    --color-font-200: #868b99;
    --color-font-300: #555d70;
    --color-font-400: #242e47;
    --color-font-500: #0c1732;
    --color-font-600: #0b152d;
    --color-font-700: #081023;
    --color-font-800: #060c19;
    --color-font-900: #04070f;

    --color-green-500: #1ca796;
    --color-darkblue-500: #041db7;
    --color-purple-400: #a5a0ff;
    --color-purple-500: #7d77ff;
    --color-blue-500: #a5e2f4;
    --color-lightblue-500: #c9edf8;
    --color-orange-500: #fe7225;
    --color-blackblue-500: #0c1732;
    --color-lila-500: #e0d9fb;
    --color-pink-500: #fae2fc;
    --color-lightyellow-500: #fcffce;
    --color-yellow-500: #f5ff77;

    --color-neutral-lightblue-100: #e9f7ff;
    --color-neutral-lightblue-200: #d3efff;
    --color-neutral-lightblue-300: #bee8ff;
    --color-neutral-lightblue-400: #a8e0ff;
    --color-neutral-lightblue-500: #92d8ff;

    --color-brand-primary-100: #feaa7c;
    --color-brand-primary-200: #fe9c66;
    --color-brand-primary-300: #fe8e50;
    --color-brand-primary-400: #fe803a;
    --color-brand-primary-500: #fe7224;
    --color-brand-primary-600: #e56720;
    --color-brand-primary-700: #cb5b1d;
    --color-brand-primary-800: #b25019;
    --color-brand-primary-900: #984416;

    --color-brand-secondary-100: #b1adff;
    --color-brand-secondary-200: #a4a0ff;
    --color-brand-secondary-300: #9792ff;
    --color-brand-secondary-400: #8a85ff;
    --color-brand-secondary-500: #7d77ff;
    --color-brand-secondary-600: #716be6;
    --color-brand-secondary-700: #645fcc;
    --color-brand-secondary-800: #5853b3;
    --color-brand-secondary-900: #4b4799;

    --color-brand-accent-100: #7cd0fe;
    --color-brand-accent-200: #66c8fe;
    --color-brand-accent-300: #51c1fe;
    --color-brand-accent-400: #3bb9fe;
    --color-brand-accent-500: #25b1fe;
    --color-brand-accent-600: #219fe5;
    --color-brand-accent-700: #1e8ecb;
    --color-brand-accent-800: #1a7cb2;
    --color-brand-accent-900: #166a98;

    // Spacing
    --spacing-3xs: 0.125rem;
    --spacing-2xs: 0.25rem;
    --spacing-xs: 0.5rem;
    --spacing-s: 0.875rem;
    --spacing-m: 1rem;
    --spacing-l: 1.25rem;
    --spacing-xl: 2rem;
    --spacing-2xl: 3rem;

    // Sizing
    --size-3xs: 0.25rem;
    --size-2xs: 0.5rem;
    --size-xs: 0.75rem;
    --size-s: 0.875rem;
    --size-m: 1rem;
    --size-l: 1.25rem;
    --size-xl: 2rem;
    --size-2xl: 3rem;

    // Border radius
    --border-radius-s: 4px;
    --border-radius-m: 8px;
    --border-radius-l: 16px;
    --border-radius-xl: 24px;
    --border-radius-round: 10rem;

    // Border width
    --border-width-s: 1px;
    --border-width-m: 2px;
    --border-width-l: 4px;

    // Shadows
    --shadow-raised: 0 2px 4px rgba(0, 0, 0, 0.1);
    --shadow-overlay: 0px 7px 29px 0px rgba(0, 0, 0, 0.15);

    --shadow-s: 0 1px 2px rgba(0, 0, 0, 0.1);
    --shadow-m: 0 2px 4px rgba(0, 0, 0, 0.15);
    --shadow-l: 0 4px 8px rgba(0, 0, 0, 0.2);
    --shadow-xl: 0 8px 16px rgba(0, 0, 0, 0.25);

    // Fonts
    --font-family-regular: "MarkPro";
    --font-family-heavy: "MarkPro_Heavy";
    --font-family-bold: "MarkPro_Bold";
    --font-family-bold-italic: "MarkPro_Bold_Italic";
    --font-family-medium: "MarkPro_Medium";
    --font-family-medium-italic: "MarkPro_Medium_Italic";
    --font-family-black: "MarkPro_Black";

    // Font sizes
    --font-size-xs: 0.75rem;
    --font-size-s: 0.875rem;
    --font-size-m: 1rem;
    --font-size-l: 1.25rem;
    --font-size-xl: 1.5rem;
    --font-size-2xl: 2.25rem;
    --font-size-3xl: 3rem;

    // Font weights
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 600;
    --font-weight-heavy: 700;
    --font-weight-black: 900;

    // Line-heights
    --line-height-xs: 1;
    --line-height-s: 1.2;
    --line-height-m: 1.5;
    --line-height-l: 1.8;
    --line-height-xl: 2;

    // Opacity
    --opacity-0: 0;
    --opacity-10: 0.1;
    --opacity-20: 0.2;
    --opacity-30: 0.3;
    --opacity-40: 0.4;
    --opacity-50: 0.5;
    --opacity-60: 0.6;
    --opacity-70: 0.7;
    --opacity-80: 0.8;
    --opacity-90: 0.9;
    --opacity-100: 1;

    // Z-index
    --z-index-0: 0;
    --z-index-10: 10;
    --z-index-100: 100;
    --z-index-1000: 1000;

    // Aspect ratios
    --aspect-ratio-1: 1;
    --aspect-ratio-4-3: 4 / 3;
    --aspect-ratio-16-9: 16 / 9;
}
