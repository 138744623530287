.learning-path-course {
    container: course-item / inline-size;
    margin-bottom: var(--spacing-l);

    > .course {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: var(--spacing-l);
        background-color: var(--color-neutral-light-100);
        border-radius: var(--border-radius-l);
 
        > .image {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100px;
            clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
            
            img {
                opacity: 0.6;
                border-radius: var(--border-radius-l) 0 0 var(--border-radius-l);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        > .information {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: var(--spacing-s);
            margin-left: 100px;

            > .title {
                font-family: var(--font-family-bold);
                font-size: var(--font-size-m);
            }

            > .metadata {
                display: flex;
                gap: var(--spacing-l);
                font-size: 0.8rem;
                color: var(--color-font-300);

                i {
                    color: var(--color-font-100);
                    margin-right: var(--spacing-xs);
                }
            }
        }

        &::before {
            position: absolute;
            content: " ";
            width: 6px;
            height: 6px;
            border-radius: 6px;
            background-color: var(--color-purple-500);
            left: -36px;
            top: 50%;
            box-shadow: 0 0 0 6px #fff;
        }

        &:hover {
            background-color: var(--color-neutral-light-200);

            > .image img {
                opacity: 1;
            }
        }

        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    @container course-item (max-width: 620px) {
        > .course {
            flex-direction: column;
            gap: var(--spacing-m);

            > .information {
                gap: var(--spacing-m);
            }

            > .actions {
                margin-left: 100px;
            }
        }
    }

    @container course-item (max-width: 400px) {
        > .course {
            > .information {
                > .metadata {
                    flex-direction: column;
                    gap: var(--spacing-xs);
                }
            }
        }
    }

    @container course-item (max-width: 340px) {
        > .course {

            > .image {
                display: none;
            }

            > .information, > .actions {
                margin-left: 0;
            }
        }
    }
}
