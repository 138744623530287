.toast-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-l);

    > .close {
        margin-top: var(--spacing-s);
        align-self: flex-start;
    }
}