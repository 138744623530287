.onboarding-modal {
    .modal-content {
        .modal-header {
            position: relative;
            justify-content: space-between;

            > img {
                height: 36px;
            }

            > .language-selection {
                display: flex;
                gap: 8px;

                > .language-flag {
                    height: 16px;
                    margin: auto;
                    border-radius: 4px;
                }
            }
        }

        .onboarding-start {
            display: flex;
            flex-direction: column;
            align-items: center;

            > .title {
                font-size: var(--font-size-3xl);
                text-align: center;
                background: linear-gradient(to right, var(--color-purple-500) 0%, var(--color-orange-500) 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            > .description {
                max-width: 60ch;
                text-wrap: balance;
                text-align: center;
            }

            > .onboarding-options {
                display: flex;
                gap: var(--spacing-m);
                margin: var(--spacing-xl) 0 var(--spacing-2xl) 0;

                > .option {
                    cursor: pointer;
                    width: 260px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: var(--spacing-s);
                    background-color: var(--color-neutral-light-200);
                    border: var(--border-width-l) solid var(--color-neutral-light-200);
                    border-radius: var(--border-radius-l);
                    padding: var(--spacing-xl) var(--spacing-l);

                    &--selected, &:hover {
                        border-color: var(--color-purple-500);
                        box-shadow: var(--shadow-l);
                    }

                    > h2 {
                        color: var(--color-purple-500);
                    }

                    > p {
                        text-align: center;
                    }

                    > .icon {
                        font-size: var(--font-size-xl);
                    }
                }
            }
        }

        .modal-footer {
            min-height: 80px;
        }
    }
}
