.learning-resource-wrapper {
    background-color: var(--color-neutral-light-100);

    .learning-resource-metadata {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    
        > .meta-item {
            display: flex;
            align-items: center;
            padding: 1rem 1.5rem;
            margin: 0.5rem 0;
    
            > .icon {
                margin-right: 1rem;
                font-size: 1.5rem;
    
                &--linkedin {
                    color: #0077b5;
                }
            }
    
            > .text {
                span {
                    font-size: 14px;
                    font-family: var(--font-family-regular);
                }
    
                p {
                    font-size: 1rem;
                    font-family: var(--font-family-bold);
                }
            }
        }
    }
}

