@import "~styles/base/mixins.scss";

.ambition-step {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);

    h2 {
        font-size: var(--font-size-xl);
        margin-bottom: var(--spacing-2xs);
    }

    p {
        font-size: var(--font-size-m);
        color: var(--color-neutral-dark-500);
    }

    form {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-m);

        .radio {
            display: flex;
            align-items: center;
            background-color: var(--color-neutral-light-200);
            padding: var(--spacing-s);
            border-radius: var(--border-radius-l);
            border: 1px solid var(--color-neutral-light-300);
            cursor: pointer;

            label {
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: var(--spacing-s);
                width: 100%;

                input[type="radio"] {
                    margin-right: var(--spacing-s);
                    transform: scale(1.3);
                }
            }

            &:hover {
                background-color: var(--color-neutral-lightblue-100);
                border-color: var(--color-neutral-lightblue-400);
            }

            &.selected {
                background-color: var(--color-neutral-lightblue-100);
                border-color: var(--color-neutral-lightblue-400);
            }
        }

        .form-control {
            padding: var(--spacing-s);
            border: var(--border-width-s) solid var(--color-neutral-light-300);
            border-radius: var(--border-radius-m);

            &:focus {
                border-color: var(--color-blue-500);
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            }
        }
    }
}
