.profession-list {
    min-height: 660px;

    .header {
        .title {
            color: var(--color-font-500);
        }
    }

    .no-results {
        text-align: center;
        margin-top: 160px;

        p {
            font-family: var(--font-family-medium);
            color: var(--color-font-400);
        }
    }
}
