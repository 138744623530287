.cookie-banner-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 -8px 12px rgba(0, 0, 0, 0.15);

    > .cookie-banner {
        background-color: var(--color-neutral-light-100);

        > .content {
            padding: var(--spacing-l) 0;
            display: flex;
            flex-direction: column;
            position: relative;

            > .title {
                font-family: var(--font-family-regular);
                font-size: var(--font-size-m);
            }

            > .description {
                color: var(--color-font-300);
                font-size: var(--font-size-s);
                margin-top: var(--spacing-s);
            }

            > .actions {
                display: flex;
                gap: var(--spacing-m);
                margin-top: var(--spacing-xl);
                flex-wrap: wrap;

                > .manage-button {
                    margin-left: auto;
                }
            }

            > .image {
                position: absolute;
                top: 0;
                right: 0;
                transform: translateY(-50%);
                width: 80px;
                height: 80px;
                object-fit: cover;
            }
        }
    }

    > .cookie-footer {
        background-color: var(--color-neutral-light-300);

        > .content {
            display: flex;
            gap: var(--spacing-l);
            padding: var(--spacing-xs) 0;

            > a {
                color: var(--color-font-300);
                font-size: var(--font-size-xs);
                font-family: var(--font-family-medium);
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
