.navigation-drawer-item {
    + .navigation-drawer-item {
        margin-top: var(--spacing-xs);
    }

    > .nav-link {
        display: grid;
        grid-template-columns: 32px 1fr;
        gap: var(--spacing-s);
        padding: var(--spacing-s) var(--spacing-s);
        color: var(--_color-navigation-bar-text);
        font-family: var(--font-family-medium);
        border-radius: var(--border-radius-l);
        transition: none;

        .icon {
            font-size: var(--font-size-l);
            color: var(--color-font-300);
            display: flex;
            justify-content: center;
            align-items: baseline;
        }

        .title {
            font-size: var(--font-size-m);
            text-wrap: nowrap;
        }

        .nav-tooltip {
            display: none;
        }

        &.active,
        &:hover {
            color: var(--color-neutral-light-0);
            background-color: var(--color-brand-primary-500);

            .icon {
                color: var(--color-neutral-light-0);
            }
        }
    }

    &.folded {
        > .nav-link {
            display: flex;
            justify-content: center;
            padding: var(--spacing-s) var(--spacing-s);
            position: relative;

            .icon {
                font-size: var(--font-size-xl);
            }

            .title {
                display: none;
            }

            &:hover {
                border-radius: var(--border-radius-l) 0 0 var(--border-radius-l);

                .nav-tooltip {
                    display: flex;
                    position: absolute;
                    width: fit-content;
                    text-wrap: nowrap;
                    left: 80%;
                    top: 0;
                    padding: var(--spacing-s);
                    border-radius: 0 var(--border-radius-l) var(--border-radius-l) 0;
                    background-color: var(--color-brand-primary-500);
                    color: var(--color-neutral-light-0);
                }
            }
        }
    }
}
