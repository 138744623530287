.requirement-task-list {

    > .title {
        margin-bottom: 1rem;
    }

    > .list {
        max-height: 150px;
        overflow-y: auto;

        > .task {
            margin: 12px 0;
            line-height: 1;

            &:first-child {
                margin-top: 4px;
            }

            &:hover {
                color: var(--color-orange-500);

                a {
                    color: var(--color-orange-500);
                }
            }
        }
    }
}