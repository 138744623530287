.vouchers-faq {
    container-type: inline-size;

    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: var(--spacing-xl);
         
        > .help {

            a {
                color: var(--color-font-900);
                text-decoration: underline;
            }
        }
    }

    > .faq {
        > .faq-item {
            display: grid;
            grid-template-columns: 30px auto;
            grid-template-rows: auto;
            gap: 0 var(--spacing-s);
            padding: var(--spacing-m) var(--spacing-l);
            // Align answers to the top of the grid cell
            align-items: start;

            .icon {
                grid-column: 1;
                grid-row: 1;
                font-size: 24px;
                color: var(--color-brand-secondary-500);
            }

            .question {
                grid-column: 2;
                grid-row: 1;
                font-family: var(--font-family-medium);
                font-size: var(--font-size-m);
            }

            .answer {
                grid-column: 2;
                grid-row: 2;
            }
        }
    }

    @container (max-width: 768px) {
        > .header {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-m);
        }
    }
}