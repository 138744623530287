@import "~styles/base/mixins.scss";

.measurement-results {
    @include fullPageHeight;
    background-color: var(--color-neutral-lightblue-100);

    .header-container {
        background-color: var(--color-main-background);
    }

    .result {
        > .title {
            color: var(--color-purple-500);
            position: relative;
        }

        > .measurement-title {
            background-color: rgba(255, 255, 255, 0.5);
            padding: var(--spacing-xs) var(--spacing-s);
            border-radius: var(--border-radius-m);
            color: var(--color-neutral-dark-400);
            width: fit-content;
            position: relative;
        }

        > .result-summary {
            max-width: 65ch;
            margin: var(--spacing-l) 0;
            position: relative;
        }

        > .background-img {
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: "";
                display: block;
                background-image: linear-gradient(to bottom, var(--color-main-background), transparent 30%);
            }

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: "";
                display: block;
                background-image: linear-gradient(to right, var(--color-main-background), transparent 70%);
            }

            > img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    .recommended-professions {
        > .title {
            margin-bottom: var(--spacing-m);
        }
    }

    @media (max-width: 1300px) {
        .result {
            > .background-img {
                width: 100%;
                mask-image: none;
                opacity: 0.4;
                filter: blur(3px);
            }
        }
    }
}
