@import "~styles/base/mixins.scss";

.feedback-overview {
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;

    .background-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
        z-index: -1;

        svg {
            position: relative;
            display: block;
            width: calc(100% + 1.3px);
            height: 30vh;
            transform: rotateY(180deg);
            fill: var(--color-neutral-lightblue-300);
        }
    }

    .content {
        padding-top: 200px;

        .overview-card {
            background-color: var(--color-neutral-light-0);
            border-radius: var(--border-radius-xl);
            box-shadow: var(--shadow-raised);
            padding: var(--spacing-xl);

            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

        }
    }

    .title {
        color: var(--color-brand-secondary-500);
    }

    .description {
        max-width: 65ch;
    }
}
