.scroll-to-top {
    position: fixed;
    bottom: var(--spacing-l);
    right: var(--spacing-l);
    background-color: var(--color-neutral-light-0);
    border: none;
    width: 48px;
    height: 48px;
    border-radius: var(--border-radius-round);
    z-index: 1;

    i {
        color: var(--color-brand-primary-500);
    }

    &:hover {
        background-color: var(--color-brand-primary-500);

        i {
            color: var(--color-neutral-light-0);
        }
    }
}