@import "~styles/base/mixins.scss";

.measurement-content {
    @include fullPageHeight;
    position: relative;

    .background-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
        z-index: -1;

        svg {
            position: relative;
            display: block;
            width: calc(100% + 1.3px);
            height: 20vh;
            transform: rotateY(180deg);
            fill: var(--color-neutral-lightblue-300);
        }
    }

    > .form-container {
        > .form-card {
            padding: 0 var(--spacing-2xl) var(--spacing-2xl) var(--spacing-2xl);
            background-color: var(--color-neutral-light-0);
            border-radius: var(--border-radius-xl);

            @media screen and (max-width: 768px) {
                padding: 0 var(--spacing-l) var(--spacing-l) var(--spacing-l);
            }

            > .progression {
                display: flex;
                align-items: center;
                padding-top: var(--spacing-xl);
                margin-bottom: var(--spacing-s);

                @media screen and (max-width: 768px) {
                    padding-top: var(--spacing-l);
                }

                > .current {
                    font-family: var(--font-family-heavy);
                    font-size: var(--font-size-2xl);
                    color: var(--color-brand-secondary-500);
                    line-height: var(--line-height-xs);
                    margin-right: var(--spacing-xs);
                }

                > .text {
                    font-family: var(--font-family-heavy);
                    font-size: var(--font-size-l);
                    color: var(--color-neutral-light-500);
                    line-height: var(--line-height-xs);
                    margin-right: 6px;
                }

                > .total {
                    font-family: var(--font-family-heavy);
                    font-size: var(--font-size-l);
                    color: var(--color-neutral-light-500);
                    line-height: var(--line-height-xs);
                }
            }

            form {
                #root__title,
                #root__description {
                    display: none;
                }

                .form-group,
                .form-category {
                    padding-bottom: 2rem;

                    &:last-child {
                        padding-bottom: 0;
                    }

                    .control-label {
                        font-family: var(--font-family-heavy);
                        font-size: var(--font-size-xl);
                        padding-bottom: var(--spacing-l);

                        @media screen and (max-width: 768px) {
                            font-size: var(--font-size-l);
                        }

                        .required {
                            margin-left: 6px;
                            color: var(--color-danger-500);
                        }
                    }

                    input[type="range"] {
                        padding: 0;
                    }

                    .radio,
                    .checkbox {
                        margin-bottom: var(--spacing-s);
                        background-color: var(--color-neutral-light-200);
                        padding: var(--spacing-m);
                        border-radius: var(--border-radius-l);
                        border: 1px solid var(--color-neutral-light-300);
                        cursor: pointer;

                        label {
                            cursor: pointer;
                        }

                        label span {
                            display: flex;
                            align-items: center;
                            gap: var(--spacing-s);

                            input[type="checkbox"],
                            input[type="radio"] {
                                transform: scale(1.3);
                            }
                        }

                        &:has(input[type="radio"]:checked),
                        &:hover {
                            background-color: var(--color-neutral-lightblue-100);
                            border-color: var(--color-neutral-lightblue-400);
                        }
                    }

                    &.has-error {
                        .control-label {
                            color: var(--color-danger-500);
                        }
                    }
                }

                .previous {
                    text-decoration: none;
                    background-color: transparent;
                    font-family: var(--font-family-heavy);
                    color: var(--color-neutral-light-600);
                    border: none;

                    & + button {
                        margin-left: var(--spacing-xl);
                    }
                }
            }
        }
    }
}
