.profile-requirement-completion-tile-content {
    position: relative;
    container: requirement / inline-size;

    > .title {
        margin-bottom: 1.5rem;
    }

    > .profile-progress {
        //margin-bottom: 1.5rem;

        .progress {
            --bs-progress-height: 1.5rem;
            --bs-progress-bar-bg: var(--paars);
            --bs-progress-border-radius: var(--border-radius-xl);
            --bs-progress-font-size: 14px;
            font-family: var(--font-family-medium);
        }
    }
}
