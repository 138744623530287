.skeleton-swiper {
    .title {
        margin-bottom: 1.5rem;
    }

    > .swiper {
        display: flex;
        gap: 16px;
        overflow-y: hidden;

        > span {
            height: 280px;
            min-width: 440px;
        }

        @media screen and (max-width: 768px) {
            > span {
                min-width: 100%;
                height: 240px;
            }
        }
    }

    + .skeleton-swiper {
        margin-top: 3rem;
    }
}
