.currently-learning-tile-content {
    position: relative;
    display: flex;
    flex-direction: column;
    
    > .title {
        margin-bottom: 1.5rem;
    }

    > .learning-list {
        
        .subtitle {
            font-size: 1rem;
            font-family: var(--font-family-bold);
            color: var(--donkerblauw);
            padding: 0.5rem 0;
        }

        .learning-item {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 1.5rem;
            padding: 4px 0;

            > .name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            > .link {
                width: fit-content;
                text-decoration: underline;

                &:hover {
                    color: var(--donkerblauw);
                }
            }
        }
    }

    > .no-learning-items {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .description {
            flex-grow: 1;
        }

        .link {
            align-self: flex-end;
        }
    }
}
