@import "~bootstrap/scss/bootstrap-utilities";

.navigation-drawer {
    --_color-navigation-bar-background: var(--color-neutral-light-0);
    --_color-navigation-bar-text: var(--color-font-400);
    --_spacing-navigation-bar-padding: var(--spacing-xs);

    padding: var(--_spacing-navigation-bar-padding);
    background-color: var(--_color-navigation-bar-background);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 var(--border-radius-l) 0;

    .logo {
        display: flex;
        align-items: center;
        padding: 0 var(--spacing-s);
        height: 48px;
        margin-top: var(--spacing-s);
        margin-bottom: var(--spacing-2xl);

        img {
            height: 100%;
            width: auto;
        }
    }

    .navigation-drawer-nav {
        flex-grow: 1;
    }

    .navigation-footer {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-s);

        .account-cta {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-xs);
            padding: var(--spacing-s);
            border-radius: var(--border-radius-l);
            background-color: var(--color-brand-secondary-100);
            position: relative;
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                top: 70%;
                left: -20px;
                width: 100%;
                height: 50%;
                transform: scaleY(-1);
                background-image: url('../../../public/assets/img/WIHV_Background_Shape_Purple.svg');
                background-position: bottom;
                background-size: contain;
                background-repeat: no-repeat;
            }

            > .link {
                background-color: var(--color-neutral-light-0);
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                border-radius: var(--border-radius-round);

                &:hover {
                    background-color: var(--color-neutral-light-100);
                }
            }
        }

        .toggle {
            align-self: flex-end;
            width: fit-content;
            border: none;
            font-size: var(--font-size-l);
            padding: var(--spacing-xs) var(--spacing-s);
            border-radius: var(--border-radius-m);
            background-color: var(--color-neutral-light-200);

            &:hover {
                background-color: var(--color-neutral-light-300);
            }
        }
    }

    &.folded {
        border-radius: 0 var(--border-radius-s) var(--border-radius-s) 0;

        .logo {
            padding: 0;
            margin-top: 0;
            justify-content: center;
        }

        .navigation-footer {
            .toggle {
                align-self: center;
            }
        }
    }

    &.mobile {
        border-radius: 0;

        .logo {
            justify-content: center;
            height: 32px;
            margin-top: var(--spacing-s);
            margin-bottom: var(--spacing-xl);
        }
    }
}
