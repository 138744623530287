.attributes-result-wrapper {
    container-type: inline-size;

    .attributes-result {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-m);

        > .attribute {
            background-color: var(--color-neutral-light-0);
            padding: var(--spacing-s);
            border-radius: var(--border-radius-l);

            > .title {
                font-size: var(--font-size-xl);
                color: var(--color-brand-primary-500);
                margin-bottom: var(--spacing-s);
            }

            > .description {
                text-wrap: balance;
            }
        }
    }

    @container (min-width: 600px) {
        .attributes-result {
            flex-direction: row;
        }
    }
}
