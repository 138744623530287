.matrix-grid {
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
    border-collapse: collapse;
    width: 100%;

    .header-row {
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding: 10px;

        .empty-cell {
            flex: 1;
        }

        .column-header {
            flex: 1;
            text-align: center;
            font-weight: bold;
        }
    }

    .statement {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #ccc;

        .title-cell {
            background-color: #f2f2f2;
            padding: 10px;
            font-weight: bold;
        }

        .row {
            display: flex;
            align-items: center;
            padding: 10px;

            .row-title {
                flex: 1;
            }

            .radio-cell {
                flex: 1;
                text-align: center;
            }
        }
    }
}
