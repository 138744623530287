.favorite-toggle {
    > .icon {
        cursor: pointer;

        &--favorite {
            --_color-star: var(--color-favorite-300);
            --_color-star-hover: var(--color-favorite-400);

            &.icon--light {
                --_color-star: var(--color-favorite-300);
                --_color-star-hover: var(--color-favorite-400);
            }

            &.icon--dark {
                --_color-star: var(--color-favorite-600);
                --_color-star-hover: var(--color-favorite-700);
            }
        }

        &--unfavorite {
            --_color-star: var(--color-favorite-300);
            --_color-star-hover: var(--color-favorite-500);

            &.icon--light {
                --_color-star: var(--color-neutral-light-0);
                --_color-star-hover: var(--color-favorite-400);
            }

            &.icon--dark {
                --_color-star: var(--color-neutral-dark-900);
                --_color-star-hover: var(--color-favorite-600);
            }
        }

        color: var(--_color-star);

        &:hover {
            color: var(--_color-star-hover);
            scale: 1.1;
        }
    }
}
