.form-card {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: var(--border-radius-xl);
    border: none;

    > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--color-neutral-light-0);
        border: none;
        padding: 1.25rem 2rem;
        border-radius: 1rem;
        font-family: var(--font-family-bold);
        font-size: 1.5rem;
        cursor: pointer;
        
        > h2 {
            color: var(--color-brand-secondary-500);
        }

        > .toggle-icon {
            color: var(--color-brand-secondary-500);
            line-height: 1;
        }

        &:hover {
            background-color: var(--color-neutral-light-100);
        }
    }

    > .content {
        border: none;
        padding: 2rem;

        &.content--open {
            height: auto;
        }

        &.content--closed {
            display: none;
            height: 0;
            padding: 0;
            overflow: hidden;
            position: relative;
        }
    }
}