@import "~styles/base/mixins.scss";

.measurement-start {
    @include fullPageHeight;
    background-color: var(--color-neutral-lightblue-100);

    .header-container {
        background-color: var(--color-main-background);
    }

    .header {
        > .background-img {
            position: absolute;
            top: 0;
            right: 0;
            width: 60%;
            height: 100%;

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: "";
                display: block;
                background-image: linear-gradient(to bottom, var(--color-main-background), transparent 30%);
            }

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: "";
                display: block;
                background-image: linear-gradient(to right, var(--color-main-background), transparent 70%);
            }

            > img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .info {
            > .title {
                color: var(--color-brand-secondary-500);

                .favorite-toggle {
                    display: inline-flex;
                    margin-left: var(--spacing-m);
                    font-size: var(--font-size-l);
                }
            }
        }

        .metadata {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-s);
            background-color: var(--color-neutral-light-0);
            border-radius: var(--border-radius-xl);
            padding: var(--spacing-xl);

            > .title {
                color: var(--color-purple-500);
            }

            > .duration {
                .icon {
                    color: var(--color-purple-500);
                }
            }

            > .attribute-category {
                .icon {
                    color: var(--color-purple-500);
                }
            }

            > .warning {
                color: var(--color-danger-600);
            }
            
        }
    }

    @media (max-width: 992px) {
        .header {
            > .background-img {
                width: 100%;
                mask-image: none;
                opacity: 0.4;
                filter: blur(3px);
            }
        }
    }
}
