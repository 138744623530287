.category-attributes {
    --_color-category-500: var(--color-neutral-light-0);
    --_color-category-100: var(--color-neutral-light-0);

    background-color: var(--color-neutral-light-0);
    padding: var(--spacing-xl);
    border-radius: var(--spacing-xl);
    position: relative;
    box-shadow: var(--shadow-raised);

    &.category-1 {
        --_color-category-500: #1e852f;
        --_color-category-100: #1e852f21;
    }

    &.category-2 {
        --_color-category-500: #278eee;
        --_color-category-100: #278eef21;
    }

    &.category-3 {
        --_color-category-500: #9b27c9;
        --_color-category-100: #9b27cf21;
    }

    > header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: var(--spacing-s);
        border-bottom: var(--border-width-m) solid var(--color-neutral-light-200);
        margin-bottom: var(--spacing-s);

        > .title {
            &::before {
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
                background-color: var(--_color-category-500);
                border-radius: var(--border-radius-round);
                margin-right: var(--spacing-m);
            }
        }

        > .count {
            position: absolute;
            top: 0;
            right: 3rem;
            width: 48px;
            height: 56px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            padding-top: 4px;
            font-size: 24px;
            font-family: var(--font-family-heavy);
            background-color: var(--_color-category-100);
            color: var(--color-font-300);
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 70%, 0 100%);
        }
    }

    .subcategory {
        > .subtitle {
            width: 100%;
            display: flex;
            font-size: var(--font-size-s);
            font-family: var(--font-family-regular);
            color: var(--color-font-200);
            margin-left: 8px;
            margin-bottom: 4px;
            padding-top: 16px;
        }

        > .attributes {
            display: flex;
            flex-wrap: wrap;
            gap: var(--spacing-xs);

            > .attribute {
                padding: 8px 18px;
                border-radius: var(--border-radius-round);
                font-size: var(--font-size-m);
                background-color: #eeeeee2d;
                border: var(--border-width-m) var(--color-neutral-light-300) solid;

                &:hover {
                    background-color: var(--_color-category-100);
                    border-color: var(--_color-category-500);
                    cursor: pointer;
                }

                &.negative-feedback {
                    border-style: dashed;
                    color: var(--color-font-200);
                    text-decoration: line-through;
                }

                > .icon {
                    padding-left: var(--spacing-xs);
                    color: var(--color-font-200);
                }
            }
        }
    }

    .empty-subcategory-message {
        width: 100%;
        font-size: var(--font-size-s);
        color: var(--color-font-100);
        margin-left: 8px;
        margin-bottom: 8px;
        padding-top: 0px;
    }
}
