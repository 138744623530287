.cookie-preferences-modal {
    .modal-content {
        .modal-body {
            max-height: 40vh;
            overflow-y: auto;

            > .subtitle {
                font-size: var(--font-size-m);
                margin-bottom: var(--spacing-xs);
                color: var(--color-font-300);
            }

            > .description {
                font-size: var(--font-size-s);
                margin-bottom: var(--spacing-l);
            }

            > .cookie-categories {
                display: flex;
                flex-direction: column;
                gap: var(--spacing-xs);
                margin-bottom: var(--spacing-l);

                .accordion {
                    --bs-accordion-btn-focus-border-color: transparent;
                    --bs-accordion-btn-focus-box-shadow: none;
                    --bs-accordion-active-color: var(--color-darkblue-500);

                    .accordion-item {
                        border-radius: var(--border-radius-m);
                    }

                    .accordion-header {
                        .accordion-button {
                            display: flex;
                            justify-content: space-between;
                            border-radius: var(--border-radius-m);
                            background-color: var(--color-neutral-light-200);
                            font-family: var(--font-family-bold);

                            .header-content {
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                                margin-right: var(--spacing-m);

                                .strictly-necessary-switch {
                                    display: flex;
                                    gap: var(--spacing-s);
                                    align-items: center;

                                    span {
                                        font-size: var(--font-size-xs);
                                        background-color: var(--color-neutral-light-300);
                                        color: var(--color-font-300);
                                        font-family: var(--font-family-medium);
                                        padding: 2px 10px;
                                        text-align: center;
                                        border-radius: var(--border-radius-round);
                                    }
                                }
                            }

                            &:focus-visible {
                                outline: -webkit-focus-ring-color auto 1px !important;
                            }
                        }
                    }
                }
            }

            > .contact-info {
                padding: var(--spacing-s);
                border: var(--border-width-s) solid var(--color-neutral-light-300);
                border-radius: var(--border-radius-m);

                > .title {
                    font-size: var(--font-size-m);
                    color: var(--color-font-300);
                    margin-bottom: var(--spacing-xs);
                }

                > .description {
                    font-size: var(--font-size-s);

                    a {
                        font-family: var(--font-family-medium);

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .modal-footer {
            display: flex;

            > .save {
                margin-left: auto;
            }
        }
    }
}
