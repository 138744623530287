@import "~bootstrap/scss/bootstrap-utilities";

:root {
    --sizing-navigation-width: 260px;
    --sizing-header-height: 72px;
    --grid-template-columns: var(--sizing-navigation-width) 1fr;
    --grid-template-rows: var(--sizing-header-height) 1fr;
}

.main-wrapper {
    display: grid;
    grid-template-columns: var(--grid-template-columns);
    grid-template-rows: var(--grid-template-rows);
    grid-template-areas:
        "nav header"
        "nav main";

    .wihv-navigation {
        grid-area: nav;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: var(--sizing-navigation-width);
        z-index: var(--z-index-100);
        transition: width 0.3s ease;
    }

    .wihv-header {
        grid-area: header;
        position: sticky;
        top: 0;
        z-index: var(--z-index-100);
        width: inherit;
    }

    .wihv-main {
        grid-area: main;
    }

    &.mobile {
        grid-template-columns: 1fr;
        grid-template-areas:
            "header"
            "main";

        &.folded {
            .wihv-navigation {
                display: none;
            }
        }

        &.unfolded {
            .wihv-navigation {
                display: flex;
                position: fixed;
                top: var(--sizing-header-height);
                left: 0;
                height: calc(100vh - var(--sizing-header-height));
                transition: none;
            }
        }
    }
}
