@import "~styles/base/mixins.scss";

.vouchers-overview {
    @include fullPageHeight;

    .hero {
        text-align: center;

        > .title {
            font-size: var(--font-size-3xl);
            color: var(--color-purple-500);
        }

        > .subtitle {
            font-size: var(--font-size-l);
            font-family: var(--font-family-medium);
        }
    }

    .plans {
        > .plan-comparison {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: var(--spacing-xl);

            > .plan {
                background-color: var(--color-neutral-light-0);
                border-radius: var(--border-radius-xl);
                padding: var(--spacing-l);
                width: 100%;
                max-width: 350px;
                box-shadow: var(--shadow-raised);
                position: relative;
                display: flex;
                flex-direction: column;
                border: var(--border-width-m) solid transparent;

                &--start {
                    border-color: var(--color-purple-500);
                }

                .show-more-link {
                    display: inline-block;
                    text-align: center;
                    padding-left: 28px;
                    font-weight: bold;
                    text-decoration: underline;
                    color: var(--color-neutral-dark-500);
                }
                
                .show-more-link:hover {
                    color: var(--color-neutral-purple-500);
                }

                > .header {
                    padding-bottom: var(--spacing-l);
                    border-bottom: var(--border-width-s) solid var(--color-neutral-light-400);
                    margin-bottom: var(--spacing-l);

                    h2 {
                        font-family: var(--font-family-black);
                        margin-bottom: var(--spacing-s);
                    }

                    .label {
                        position: absolute;
                        top: var(--spacing-s);
                        right: var(--spacing-s);
                        background-color: var(--color-purple-500);
                        color: var(--color-neutral-light-0);
                        padding: var(--spacing-xs) var(--spacing-s);
                        border-radius: var(--border-radius-round);
                    }
                }

                > .price {
                    font-size: 1.25rem;
                    font-weight: bold;
                    padding-bottom: var(--spacing-l);
                    border-bottom: var(--border-width-s) solid var(--color-neutral-light-400);
                    margin-bottom: var(--spacing-l);

                    > .billing-cycle {
                        font-size: var(--font-size-m);
                        color: var(--color-neutral-light-600);
                    }
                }
                
                .description {
                    min-height: 336px;
                    white-space: pre-line;
                }
                
                > .feature-list {
                    margin-bottom: var(--spacing-l);
                    flex-grow: 1;

                    >ul .feature {
                        display: flex;
                        align-items: center;
                        gap: var(--spacing-s);
                        padding: var(--spacing-2xs) 0;

                        .icon {
                            color: var(--color-success-500);
                        }

                        &--disabled {
                            color: var(--color-neutral-light-600);

                            .icon {
                                color: var(--color-neutral-light-600);
                            }
                        }
                    }
                }

                > button {
                    width: 100%;

                    + button {
                        margin-top: var(--spacing-m);
                    }
                }

                > a {
                    width: 100%;
                    text-align: center;
                }

                > .owned-status {
                    width: 100%;
                    text-align: center;

                    .icon {
                        color: var(--color-success-500);
                    }
                }
            }
        }
    }

    .faq {
        background-color: var(--color-main-background-raised);
    }

    @media screen and (max-width: 768px) {
        &::before {
            height: 350px;
            clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
        }        
    }
}
