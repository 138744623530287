@import "~styles/base/mixins.scss";

.page-not-found {
    @include fullPageHeight;

    .content {
        min-height: 75vh;
        display: flex;
        align-items: center;
        position: relative;

        .image {
            border-radius: 50%;
            width: 100%;
            height: auto;
        }
    }
}
