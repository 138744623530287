.discover-platform-tile-content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    > .title {
        margin-bottom: var(--spacing-m);
    }

    > .description {
        font-size: var(--font-size-s);
        margin-bottom: var(--spacing-xl);
        flex: 1;
    }

    > a {
        align-self: flex-end;
    }
}
