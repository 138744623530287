/* Reset styling list */
ol, ul {
	list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

a {
    text-decoration: none;

    &:hover {
        color: inherit;
    }
}