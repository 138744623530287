.profile-requirement-category-completion-tile-content {
    position: relative;

    > .title {
        margin-bottom: 1.5rem;
    }

    > .category-progress {
        margin-bottom: 1rem;

        .progress {
            --bs-progress-height: 1.5rem;
            --bs-progress-bar-bg: var(--paars);
            --bs-progress-border-radius: var(--border-radius-xl);
            --bs-progress-font-size: 14px;
            font-family: var(--font-family-medium);
        }
    }

    > .info {
        > .description {
            margin-bottom: 1.5rem;
        }

        > .completed {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 1rem;

            > .icon {
                color: #41b883;
                font-size: 48px;
            }
        }
    }
}
