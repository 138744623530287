table {
    background-color: var(--color-neutral-light-0);
    border-radius: var(--border-radius-l);

    thead {
        tr {
            color: var(--color-font-600);

            th {
                padding-top: 1rem !important;
                padding-bottom: 1rem !important;
                background-color: var(--color-neutral-light-100) !important;
            }

            th:first-child {
                border-top-left-radius: var(--border-radius-l);
            }

            th:last-child {
                border-top-right-radius: var(--border-radius-l);
            }
        }
    }

    tbody {
        border-radius: 0 0 var(--border-radius-l) var(--border-radius-l);

        tr {
            cursor: pointer;

            td {
                padding-top: 1rem !important;
                padding-bottom: 1rem !important;
            }

            &.opened {
                td {
                    padding-top: 2rem !important;
                    padding-bottom: 2rem !important;
                }
            }

            td:nth-child(4) {
                background-color: rgba(192, 255, 184, 0.2);
            }

            td:nth-child(5) {
                background-color: rgba(209, 244, 255, 0.2);
            }

            td:nth-child(6) {
                background-color: rgba(212, 212, 212, 0.2);
            }

            &:hover {
                background-color: var(--color-neutral-light-100);

                .td-attribute-category {
                    font-family: var(--font-family-heavy);
                }
            }

            &:last-child {
                td:first-child {
                    border-bottom-left-radius: var(--border-radius-l);
                    border: none;
                }
                td:last-child {
                    border-bottom-right-radius: var(--border-radius-l);
                    border: none;
                }
            }
        }
    }

    .th-ranking,
    .td-ranking {
        width: 100px;
    }

    .th-attribute-category,
    .td-attribute-category {
        width: 140px;
    }

    .th-recommended,
    .td-recommended {
        width: 140px;
    }
}

@media screen and (max-width: 768px) {
    table {
        thead {
            tr {
                th {
                    font-size: var(--font-size-s);
                }
            }
        }

        .th-ranking,
        .td-ranking {
            width: 40px;
        }

        .th-attribute-category,
        .td-attribute-category {
            width: 120px;
        }

        .th-recommended,
        .td-recommended {
            width: 120px;
        }
    }
}
