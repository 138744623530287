.onboarding-form {
    min-height: 620px;

    > .stepper {
        margin-bottom: var(--spacing-m);
        padding-top: 0;
        padding-bottom: 0;
    }

    > .form-steps {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-s);
    }

    > .actions {
        display: flex;
        justify-content: space-between;
        gap: var(--spacing-s);
        margin-top: var(--spacing-m);
    }
}
