.profile-card {
    container-type: inline-size;

    &.card {
        background: none;
        border: none;
    }

    .card-header {
        background-color: var(--color-purple-500);
        color: var(--color-neutral-light-0);
        border: none;
        padding: 1rem 2rem;
        border-radius: var(--border-radius-xl) var(--border-radius-xl) 0 0;

        display: flex;
        justify-content: space-between;

        .card-title {
            margin-bottom: 0;
        }

        button {
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 0;
            width: 32px;
            height: 32px;
            background-color: var(--color-neutral-light-0);
            color: var(--color-blackblue-500);
            border-radius: 50px;
            border: none;

            i {
                position: relative;
                top: 1px;
                left: 1px;
            }

            &:hover,
            &:focus {
                border: 2px solid var(--color-blackblue-500);
            }
        }
    }

    .card-body {
        background-color: var(--color-neutral-light-0);
        color: var(--color-blackblue-500);
        border: none;
        border-radius: 0 0 25px 25px;
        padding: 2rem;

        h3 {
            font-size: var(--font-size-m);
            color: var(--color-darkblue-500);
        }

        ul {
            padding: 0;
            line-height: 1.7;
            font-size: 14px;
            text-transform: none !important;
        }

        .card-text .card-item:not(:last-of-type) {
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgba(12, 23, 50, 0.1);
        }

        .card-item {
            .subtitle {
                margin: 0;
                font-size: 16px;
                font-family: var(--font-family-medium);
            }

            .type {
                margin-left: 5px;
                font-size: 12px;
            }

            .description {
                font-size: 15px;
                margin-bottom: 0px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .time-period {
                font-size: 12px;
            }
        }

        .profile-completion-required {
            position: relative;
            border-radius: var(--border-radius-l);
            border: var(--border-width-m) dashed var(--color-neutral-light-400);
            padding: var(--spacing-xs) var(--spacing-s);

            > p {
                text-align: center;
                font-family: var(--font-family-bold);
                background: linear-gradient(109.6deg, rgb(243, 136, 136) 11.2%, rgb(196, 157, 247) 75.1%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;              
            }

            > .icon {
                position: absolute;
                top: -0.825rem;
                right: -1.4rem;
                font-size: var(--font-size-2xl);
                color: rgb(196, 157, 247);
            }
        }
    }
}

.profile-requirements {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: var(--spacing-s) var(--spacing-m);
    background: linear-gradient(109.6deg, rgb(243, 136, 136) 11.2%, rgb(196, 157, 247) 75.1%);
    border-radius: var(--border-radius-xl);
    margin-bottom: var(--spacing-m);

    > .progress {
        --bs-progress-height: var(--size-m);
        --bs-progress-bar-bg: var(--color-purple-400);
        --bs-progress-border-radius: var(--border-radius-xl);
        --bs-progress-font-size: var(--font-size-xs);
        --bs-progress-bar-color: var(--color-font-0);
        --bs-progress-bg: var(--color-neutral-light-0);
        width: 50%;
    }

    > .count {
        font-size: var(--font-size-s);
        color: var(--color-neutral-light-0);
    }

    @container (max-width: 460px) {
        flex-direction: column;
        gap: var(--spacing-xs);
        padding: var(--spacing-s) var(--spacing-s);
        border-radius: var(--border-radius-l);

        > .progress {
            width: 90%;
        }
    }
}
