.cta-full-card {
    height: 100%;
    border-radius: var(--border-radius-xl);
    position: relative;
    padding: 2rem;

    > .background-image {
        top: 0;
        right: 0;
        width: 33%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        border-radius: 0 25px 25px 0;
        clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    > .content {
        > .title {
            font-family: var(--font-family-heavy);
            padding-bottom: 0.5rem;
        }

        > .description {
            padding-bottom: 1.5rem;
            max-width: 60%;
            white-space: pre-line;

            @media screen and (max-width: 992px) {
                max-width: 100%;
            }
        }
    }
}
