.learning-path-content {
    background-color: var(--color-neutral-light-0);
    border-radius: var(--border-radius-l);
    padding: 1.5rem;

    > .header {
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        > .title {
            font-family: var(--font-family-medium);
            color: var(--color-purple-500);
            margin-bottom: var(--spacing-xs);
        }

        > .toggle {
            position: relative;
            top: -4px;
            right: -4px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: transparent;
            font-size: 20px;
            border: none;
        }
    }

    > .syllabus {
        display: flex;

        p {
            margin: 0;
            font-size: 13px;
            color: var(--color-font-200);
            font-family: var(--font-family-medium);
            text-transform: uppercase;

            &:not(:last-child)::after {
                content: "•";
                margin: 0 8px;
            }
        }
    }

    > .course-list {
        width: 95%;
        padding: 16px 0 0 32px;
        margin: 0 auto;
        margin-top: var(--spacing-xs);
        border-left: var(--border-width-m) solid var(--color-neutral-light-200);
        transition: all 0.5s ease-in-out;
        opacity: 1;

        &--closed {
            height: 0;
            opacity: 0;
            overflow: hidden;
        }
    }
}
