.question-list {
    overflow-y: auto;
    
    > .title {
        color: var(--color-purple-500);
    }
    
    > .questions {
        border: 1px solid var(--color-neutral-light-300);
        border-bottom: none;

        .question {
            cursor: pointer;
            padding: var(--spacing-s) var(--spacing-s);
            border-bottom: var(--border-width-s) solid var(--color-neutral-light-300);
    
            &:hover {
                text-decoration: line-through;
                background-color: var(--color-neutral-light-200);
            }
    
            &--completed {
                text-decoration: line-through;
            }

            > .icon {
                color: var(--color-success-400);
            }
        }
    }
}