.feedback-attributes {
    --_color-category-500: var(--color-neutral-light-0);
    --_color-category-100: var(--color-neutral-light-0);

    position: relative;

    &.category-1 {
        --_color-category-500: #1e852f;
        --_color-category-100: #1e852f21;
    }

    &.category-2 {
        --_color-category-500: #278eee;
        --_color-category-100: #278eef21;
    }

    &.category-3 {
        --_color-category-500: #9b27c9;
        --_color-category-100: #9b27cf21;
    }

    > header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: var(--spacing-s);
        border-bottom: var(--border-width-m) solid var(--color-neutral-light-200);
        margin-bottom: var(--spacing-s);

        > .title {
            &::before {
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
                background-color: var(--_color-category-500);
                border-radius: var(--border-radius-round);
                margin-right: var(--spacing-m);
            }
        }
    }

    .attributes {
        display: flex;
        flex-wrap: wrap;
        gap: var(--spacing-xs);

        > .attribute {
            padding: 8px 18px;
            border-radius: var(--border-radius-round);
            font-size: var(--font-size-m);
            background-color: #eeeeee2d;
            border: var(--border-width-m) var(--color-neutral-light-300) solid;

            &.negative-feedback {
                border-style: dashed;
                color: var(--color-font-200);
                text-decoration: line-through;
            }

            > .icon {
                padding-left: var(--spacing-xs);
                color: var(--color-font-200);
            }
        }
    }

    .empty-subcategory-message {
        width: 100%;
        font-size: var(--font-size-s);
        color: var(--color-font-100);
        margin-left: 8px;
        margin-bottom: 8px;
        padding-top: 0px;
    }
}
