@import "~styles/base/mixins.scss";

.dashboard {
    @include fullPageHeight;

    .welcome {
        color: var(--color-brand-secondary-500);
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        grid-gap: 24px;

        // Authenticated user
        .profile-completion {
            grid-column: span 3;
        }
        
        .goals {
            grid-column: span 2;
        }

        .currently-learning {
            grid-column: span 1;
        }

        .personality-completion {
            grid-column: span 1;
        }

        .skills-completion {
            grid-column: span 1;
        }

        .potential-completion {
            grid-column: span 1;
        }

        // Visitor
        .create-profile {
            grid-column: span 1;
        }

        .discover-platform-professions {
            grid-column: span 1;
        }

        @media screen and (max-width: 1200px) {
            grid-template-columns: repeat(2, 1fr);

            .profile-completion {
                grid-column: span 2;
            }
        }

        @media (max-width: 768px) {
            grid-template-columns: 1fr;

            .profile-completion {
                grid-column: span 1;
            }
        }
    }
}
