@import "~styles/base/mixins.scss";

.ad-info {
    @include fullPageHeight;
    background-color: var(--lila);
    display: flex;
    justify-content: center;
    align-items: center;

    .ad-info__bg-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .ad-info__content {
        position: relative;
        background-color: var(--wit);
        border-radius: var(--border-radius-xl);
        padding: 2rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 25px 5px rgba(15, 23, 44, 0.2);
    }
}
