.redeem-voucher-modal {
    .content {
        input {
            border: var(--border-width-s) solid var(--color-neutral-light-500);
        }

        .info {
            padding-left: var(--spacing-s);
            margin-top: var(--spacing-l);
            color: var(--color-neutral-light-700);
        }
    }
}
