@import "~styles/base/mixins.scss";

.profession-card {
    display: grid;
    grid-template-rows: auto 1fr;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--border-radius-xl);
    background-color: var(--wit);
    @include cardHoverEffect;

    > .img {
        width: 100%;
        aspect-ratio: 16/9;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    > .favorite {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 48px;
        height: 48px;
        border-radius: 0 0 0 var(--border-radius-xl);
        background-color: rgba(0, 0, 0, 0.4);

        > div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    > .content {
        padding: 16px;
        height: 100%;
        display: flex;
        flex-direction: column;

        > .title {
            color: var(--paars);
            font-family: var(--font-family-medium);
            font-size: 1.25rem;
            margin-bottom: 1rem;
        }

        > .meta {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            margin: 0.75rem 0;

            .meta-item {
                color: var(--zwartblauw);
                font-size: 12px;
                padding: 4px 10px;
                border-radius: var(--border-radius-xl);
                border: 1px solid rgba(0, 0, 0, 0.15);
            }
        }

        > .description {
            font-size: 1rem;
            margin-bottom: 1rem;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}
