.workstatus-step {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);

    .option-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        gap: var(--spacing-s);

        > .workstatus {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: var(--spacing-xs);
            padding: var(--spacing-l) var(--spacing-s);
            border: var(--border-width-l) solid var(--color-neutral-light-200);
            border-radius: var(--border-radius-m);
            cursor: pointer;
            background-color: var(--color-neutral-light-200);

            i {
                font-size: var(--font-size-xl);
                color: var(--color-purple-500);
            }

            &--selected {
                color: var(--color-neutral-light-0);
                background-color: var(--color-purple-500);
                border-color: var(--color-purple-500);

                i,
                h3 {
                    color: var(--color-neutral-light-0);
                }
            }

            &:hover {
                border-color: var(--color-purple-500);
            }
        }
    }
}