.radial-chart {
    position: relative;
    display: inline-block;
    transition: all 0.3s ease-in;

    .radial-chart__total {
        opacity: 0.3;
    }

    .radial-chart__progress {
        transform: rotate(-90deg);
        transform-origin: center;
        transition: all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14);
        transition-delay: 0.5s;
    }
}
