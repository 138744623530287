.filter {
    background-color: var(--wit);
    border-radius: var(--border-radius-xl);
    min-width: 150px;
    
    .dropdown-toggle {
        display: block;
        border: none;
        color: rgba(0, 0, 0, 0.5);
        border-radius: 0 var(--border-radius-xl) var(--border-radius-xl) 0;
        letter-spacing: normal;
        font-size: 1rem;
        padding: 1rem;
        text-transform: none;
        font-weight: 400;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 16px 12px !important;
        flex-grow: 1;
        text-align: start;

        &.btn,
        &:hover,
        &:focus,
        .show {
            background-color: var(--wit) !important;
            color: var(--color-font-300) !important;
        }

        &:focus {
            outline: -webkit-focus-ring-color auto 1px !important;
        }

        &::after {
            content: none;
        }

        .filter__count {
            display: inline-block;
            position: absolute;
            right: 40px;
            top: 12px;
            background-color: var(--paars);
            color: var(--wit);
            padding: 4px 8px;
            border-radius: 50%;
            min-width: 24px;
        }
    }

    .dropdown-menu {
        border: none;
        left: -18px !important;
        border-radius: 8px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        padding: 0.75rem 0.5rem;
        min-width: 280px;
        width: fit-content;
        max-height: 400px;
        overflow-y: auto;

        input[type="checkbox"] {
            scale: 1.25;
            margin-right: 0.5rem;

            + label {
                cursor: pointer;
            }
        }

        .filter__category-category {
            margin-bottom: 1rem;
            
            .filter__category {
                display: flex;
                justify-content: space-between;
                color: rgba(0, 0, 0, 0.7);
                text-transform: uppercase;
                font-size: 13px;

                i {
                    position: relative;
                    top: 2px;
                }
            }
        }


        .filter__clear {
            color: rgba(0, 0, 0, 0.7);
            margin-top: 1rem;
            border-radius: var(--border-radius-xl);
            text-align: center;
            font-size: 13px;
            text-transform: uppercase;
            cursor: pointer;

            &:hover,
            &:focus {
                background-color: var(--paars);
                color: var(--wit);
            }
        }

        .filter__checkbox {
            display: flex;
            align-items: flex-start;
            margin-bottom: 4px;

            input {
                margin-top: 1px;
            }

            label {
                line-height: 1;
            }
        }

        .filter__range-values {
            position: relative;
            top: -6px;
            display: flex;
            justify-content: space-between;
            color: rgba(0, 0, 0, 0.7);
            font-size: 13px;
        }

        .filter__range-value {
            color: var(--oranje);
            font-size: 15px;
            border: 1px solid var(--oranje);
            padding: 1px 4px;
            border-radius: 4px;
        }
    }
}
