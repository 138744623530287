.wihv-loader {
    width: 40px;
    aspect-ratio: 1;
    animation:
        l2-1 2s infinite linear,
        l2-2 1s infinite steps(1) alternate;
}

@keyframes l2-1 {
    0% {
        transform: perspective(150px) rotateX(0deg);
    }
    100% {
        transform: perspective(150px) rotateX(360deg);
    }
}
@keyframes l2-2 {
    0% {
        background: var(--color-brand-primary-500);
    }
    50% {
        background: var(--color-brand-secondary-500);
    }
}