.measurement-categories {
    .category {
        &:not(:last-child) {
            margin-bottom: var(--spacing-xl);
        }
        
        > .title {
            margin-bottom: var(--spacing-m);
        }
    }
}
